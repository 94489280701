import { ReadCourseDto, ReadCourseSurfaceDto } from './icourse';
import { ReadModuleDto } from './icourseModule';

export enum CourseStatus {
  // Keep them sorted in the order that we want them to be displayed
  Ongoing = 'Ongoing',
  Todo = 'Todo',
  Completed = 'Completed',
}

export interface UserCourseResult extends Omit<ReadCourseDto, 'modules'> {
  modules: UserModuleResult[];
  status: CourseStatus;
}

export interface UserCourseResultSurface
  extends Omit<ReadCourseSurfaceDto, 'modules'> {
  modules: UserModuleResultSurface[];
  status: CourseStatus;
}

export interface UserModuleResult extends ReadModuleDto {
  moduleId: string;
  completed: boolean;
}

export interface UserModuleResultSurface {
  moduleId: string;
  completed: boolean;
}
