import { Dispatch } from 'react';
import { SsoLogin } from '../../redux/Login/LoginSlice/actionCreators';

interface FragmentParams {
  [key: string]: string;
}

export const getSsoRedirectUrl = (email: string | null, provider?: string) => {
  let host = 'https://' + window.location.host;
  if (window.location.host.startsWith('localhost')) {
    const url = new URL('https://devacademy.cosafe.com/sso');
    host = `${url.protocol}//${url.hostname}${url.port ? `:${url.port}` : ''}`;
  }
  const uri = email
    ? '/login/?sso=' + encodeURIComponent(email)
    : '/login/?sso_provider=' + provider;
  return host + uri;
};

export const getSsoRedirectLink = (
  searchParams: URLSearchParams,
  isDispatch = false,
  dispatch: Dispatch<unknown> | null = null,
) => {
  const fragmentParams = getFragmentParams();
  if (!searchParams && Object.keys(fragmentParams).length === 0) {
    return null;
  }
  const ssoProvider = searchParams.get('sso_provider');
  let sso = searchParams.get('sso'); // Email address
  let code = searchParams.get('code');

  if (!code) {
    if (fragmentParams && fragmentParams.id_token) {
      code = fragmentParams.id_token;
    }
  }

  if (!sso) {
    if (Object.keys(fragmentParams).length !== 0 && fragmentParams.state) {
      sso = decodeURIComponent(
        decodeURIComponent(fragmentParams.state),
      ).substring(4);
    }
  }

  if (isDispatch && !!dispatch) {
    if (sso && code) {
      dispatch(SsoLogin({ code, email: sso }));
    }

    if (ssoProvider && code) {
      dispatch(SsoLogin({ email: null, code, provider: ssoProvider }));
    }
  }
  return null;
};

export const getFragmentParams = (fragment?: string) => {
  const params: FragmentParams = {};

  if (!fragment && window.location.hash) {
    fragment = window.location.hash.substring(1);
  }

  if (fragment) {
    fragment.split('&').forEach(function (part: string) {
      const values = part.split('=');
      const key = values[0];

      if (values.length >= 2) {
        values.shift();
        params[key] = values.join('=');
      }
    });
  }

  return params;
};
