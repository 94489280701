import { FC, PropsWithChildren } from 'react';
import styles from './Skeleton.module.css';
import { Avatar, Card } from '../../atoms';
import { FontSize, Text } from '../../atoms/Text';
import { CourseCard } from '../CourseCard';
import { getMockedUserCourseResult } from '../../utils/mocks/userCourseResultMocks';

type SkeletonFontSizes = Exclude<FontSize, 'body'>;

type SkeletonType = SkeletonFontSizes | 'card' | 'courseCard' | 'avatar';

const _allowedTextVariants: Array<SkeletonFontSizes> = [
  'h1',
  'h2',
  'h3',
  'label',
  'titleLarge',
  'titleSmall',
  'bodyLarge',
];

export interface SkeletonProps {
  type: SkeletonType;
  /** @default 32 */
  fillerChars?: number;
}

const getTextFillerText = (length: number) => '_'.repeat(length);
const getCourseFillerContent = (length: number) =>
  getMockedUserCourseResult({
    title: getTextFillerText(length),
    description: getTextFillerText(length),
  });

export const Skeleton = (props: SkeletonProps) => {
  const { type, fillerChars: textFillerLength = 32 } = props;

  const getContent = () => {
    const variants: [(type: SkeletonType) => boolean, string, FC][] = [
      [(type) => type === 'card', styles.cardVariant, () => <Card />],
      [
        (type) => type === 'courseCard',
        `${styles.cardVariant} ${styles.courseCardVariant}`,
        () => (
          <CourseCard
            course={getCourseFillerContent(textFillerLength)}
            onStartCourse={() => null}
          />
        ),
      ],
      [
        (type) => _allowedTextVariants.includes(type as SkeletonFontSizes),
        styles.textVariant,
        () => (
          <Text
            text={getTextFillerText(textFillerLength)}
            fontSize={type as FontSize}
          />
        ),
      ],
      [(type) => type === 'avatar', styles.avatarVariant, () => <Avatar />],
    ];

    for (let i = 0; i < variants.length; i++) {
      const [variantChecker, variantClassName, VariantContent] = variants[i];
      if (variantChecker(type)) {
        return (
          <SkeletonVariantContainerWrapper className={variantClassName}>
            <VariantContent />
          </SkeletonVariantContainerWrapper>
        );
      }
    }

    return null;
  };

  return <div className={styles.skeletonContainer}>{getContent()}</div>;
};

const SkeletonVariantContainerWrapper = ({
  className,
  children,
}: PropsWithChildren<{ className: string }>) => (
  <div className={`${styles.variantContainer} ${className}`}>
    {children}
    <div className={styles.loadingOverlay}>
      <div className={styles.loadingGlareMover}>
        <div className={styles.loadingGlare} />
      </div>
    </div>
  </div>
);
