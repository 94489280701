export const Door = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75033 4.16667C3.5189 4.16667 3.33366 4.3519 3.33366 4.58333V15.8333C3.33366 16.0648 3.5189 16.25 3.75033 16.25H9.97949C10.4397 16.25 10.8128 16.6231 10.8128 17.0833C10.8128 17.5436 10.4397 17.9167 9.97949 17.9167H3.75033C2.59842 17.9167 1.66699 16.9852 1.66699 15.8333V4.58333C1.66699 3.43143 2.59842 2.5 3.75033 2.5H9.97949C10.4397 2.5 10.8128 2.8731 10.8128 3.33333C10.8128 3.79357 10.4397 4.16667 9.97949 4.16667H3.75033Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2363 4.87323C12.5617 4.54779 13.0893 4.54779 13.4148 4.87323L18.1606 9.61904C18.3169 9.77532 18.4047 9.98728 18.4047 10.2083C18.4047 10.4293 18.3169 10.6413 18.1606 10.7976L13.4148 15.5434C13.0893 15.8688 12.5617 15.8688 12.2363 15.5434C11.9108 15.218 11.9108 14.6903 12.2363 14.3649L16.3928 10.2083L12.2363 6.05174C11.9108 5.7263 11.9108 5.19867 12.2363 4.87323Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.77539 10.2083C7.77539 9.7481 8.14849 9.375 8.60872 9.375H17.3712C17.8315 9.375 18.2046 9.7481 18.2046 10.2083C18.2046 10.6686 17.8315 11.0417 17.3712 11.0417H8.60872C8.14849 11.0417 7.77539 10.6686 7.77539 10.2083Z"
        fill="currentColor"
      />
    </svg>
  );
};
