import { Login } from '../../organisms/Login';
import styles from './LoginPage.module.css';

interface LoginPageProps {
  mode?: 'login' | 'sso';
}

export const LoginPage = (props: LoginPageProps) => {
  const { mode = 'login' } = props;
  return (
    <div className={styles.container} data-testid="login-page">
      <div className={styles.loginContainer}>
        <Login mode={mode} />
      </div>
    </div>
  );
};
