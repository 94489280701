import { IProfile } from '../../interfaces/core-api';
import { coreApi } from './coreApi';

export const getProfile = async (): Promise<IProfile> => {
  try {
    const response = await coreApi.request<IProfile>({
      resourcePath: '/profile',
      method: 'GET',
    });
    return response;
  } catch (error) {
    console.error('Error fetching profile', error);
    throw error;
  }
};
