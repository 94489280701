import { Icons } from '../../assets/icons';
import { useZoom, zoomInMapper } from '../../utils/hooks/useZoom';
import { AnimationType } from '../../utils/types/uiTypes';
import styles from './ExpandableImage.module.css';
import { useMemo, useState } from 'react';

interface ExpandableImageProps {
  imageUrl: string;
  alt: string;
}

const ANIMATION_DURATION = 300;

export const ExpandableImage = (props: ExpandableImageProps) => {
  const { imageUrl, alt } = props;

  const [isExpanded, setIsExpanded] = useState<AnimationType | boolean>(false);
  const { isLastZoomLevel, toggleZoom, zoomLevel, setZoomLevel } = useZoom();

  const handleToggleExpanded = () => {
    if (isExpanded === true) {
      setZoomLevel(0);
      setIsExpanded('ANIMATE_CLOSING');
      setTimeout(() => {
        setIsExpanded(false);
      }, ANIMATION_DURATION);
    } else if (isExpanded === false) {
      setIsExpanded('ANIMATE_OPENING');
      setTimeout(() => {
        setIsExpanded(true);
      }, ANIMATION_DURATION);
    }
  };

  const fadeClass = useMemo(() => {
    if (isExpanded === 'ANIMATE_OPENING' || isExpanded === true) {
      return styles.fadeIn;
    } else {
      return isExpanded === 'ANIMATE_CLOSING' ? styles.fadeOut : '';
    }
  }, [isExpanded]);

  const zoomClass = useMemo(() => {
    return isExpanded ? styles[zoomInMapper[zoomLevel]] : '';
  }, [isExpanded, zoomLevel]);

  return (
    <div
      onClick={isExpanded ? handleToggleExpanded : undefined}
      className={`${isExpanded ? styles.expandedOverlay : styles.imageContainer} ${fadeClass}`}
    >
      {isExpanded && <CloseButton onClose={handleToggleExpanded} />}
      <img
        onClick={isExpanded ? toggleZoom : undefined}
        className={`${isExpanded ? styles.expandedImage : styles.image}  ${isLastZoomLevel ? styles.lastZoomLevel : ''} ${fadeClass} ${zoomClass}`}
        src={imageUrl}
        alt={alt}
      />
      {!isExpanded && <ExpandButton onExpand={handleToggleExpanded} />}
    </div>
  );
};

const CloseButton = ({ onClose }: { onClose: () => void }) => (
  <div className={styles.closeButton} onClick={onClose}>
    <Icons.Cross className={styles.expandedCross} />
  </div>
);

const ExpandButton = ({ onExpand }: { onExpand: () => void }) => (
  <div onClick={onExpand} className={styles.expandButton}>
    <Icons.CrossArrows className={styles.crossArrows} />
  </div>
);

export default ExpandableImage;
