import { initReactI18next } from 'react-i18next';
import { IProfile } from './utils/interfaces/core-api';
import {
  i18n,
  availableAppLanguages,
  defaultAppLanguage,
  AppLanguage,
  getTranslationKey as commonGetTranslationKey,
  initI18n,
} from '@mono/common';

const changeLanguage = i18n.changeLanguage;

const localStorageLanguageKey = 'language';

export const setAppLanguageOnLogin = (user: IProfile): void => {
  const preferredLanguage = getAppLanguageFromUserPreferences(user);
  if (preferredLanguage) {
    updateLanguage(preferredLanguage);
  }
};

export const getCurrentAppLanguage = (): AppLanguage => {
  return getAppLanguageFromLocalStorage() || defaultAppLanguage;
};

const getAppLanguageFromLocalStorage = (): AppLanguage | null => {
  const item: string | null = localStorage.getItem(localStorageLanguageKey);
  if (!item || !availableAppLanguages.includes(item as AppLanguage)) {
    return null;
  }
  return item as AppLanguage;
};

const getAppLanguageFromUserPreferences = (
  user: IProfile,
): AppLanguage | undefined => {
  return user.preferredLanguage &&
    availableAppLanguages.includes(user.preferredLanguage as AppLanguage)
    ? (user.preferredLanguage as AppLanguage)
    : undefined;
};

export const updateLanguage = (appLanguage: AppLanguage) => {
  if (!availableAppLanguages.includes(appLanguage)) {
    return;
  }
  changeLanguage(appLanguage);

  // TODO: make sure we have consent to store the information
  localStorage.setItem(localStorageLanguageKey, appLanguage);
};

export const getTranslationKey = commonGetTranslationKey;

initI18n(
  {
    lng: getAppLanguageFromLocalStorage() || defaultAppLanguage,
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  },
  [initReactI18next],
);

export const i18nWeb = i18n;
