import React from 'react';
import { Text } from '../../atoms';
import styles from './StepTracker.module.css';
import { Icons } from '../../assets/icons';
import { TextVariant } from '../../atoms/Text';

export type StepIconType = 'empty' | 'check' | 'selected';
export interface Step {
  iconType: StepIconType;
  title: string;
  titleKey?: string;
  subtitle: string;
  subtitleKey?: string;
  active: boolean;
  onClick: () => void;
}

interface StepTrackerProps {
  steps: Step[];
}

const iconMap: Record<StepIconType, React.ElementType> = {
  empty: Icons.Circle,
  check: Icons.CheckmarkRound,
  selected: Icons.RadioChecked,
};

export const StepTracker = (props: StepTrackerProps) => {
  const { steps } = props;

  const getIcon = (iconType: StepIconType) => {
    const IconComponent = iconMap[iconType];
    return (
      <IconComponent
        className={`${styles.icon} ${iconType === 'check' ? styles.completed : undefined}`}
      />
    );
  };

  const getVariant = (step: Step): TextVariant => {
    if (!step.active) {
      return 'discrete';
    }
    return step.iconType === 'check' ? 'none' : 'attention';
  };

  return (
    <div className={styles.container}>
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          {index !== 0 && (
            <div>
              <div className={styles.divider} />
            </div>
          )}
          <div
            className={`${styles.step} ${step.active ? styles.active : ''}`}
            onClick={step.active ? undefined : step.onClick}
          >
            {getIcon(step.iconType)}
            <div className={styles.textContainer}>
              <Text
                text={step.title}
                textKey={step.titleKey}
                variant={getVariant(step)}
                fontSize="titleLarge"
                fontWeight={step.active ? 'bold' : 'regular'}
                truncate={'oneLine'}
              />
              <Text
                text={step.subtitle}
                textKey={step.subtitleKey}
                fontSize="titleSmall"
                fontWeight="regular"
                variant={getVariant(step)}
                truncate={'oneLine'}
              />
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};
