import React from 'react';
import styles from './VideoEmbedded.module.css';

export type Provider = 'youtube' | 'loom';

interface VideoEmbeddedProps {
  videoUrl: string;
  title: string;
  provider?: Provider;
}

const VideoEmbedded: React.FC<VideoEmbeddedProps> = ({
  videoUrl,
  title,
  provider = 'youtube',
}) => {
  return (
    <div className={`${styles.container} ${styles[provider]}`}>
      <iframe
        src={videoUrl}
        title={title}
        allowFullScreen
        className={styles.videoFrame}
      ></iframe>
    </div>
  );
};

export default VideoEmbedded;
