export const Circle = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="8"
        r="7.25"
        fill="white"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};
