import { CardProps } from '.';
import styles from './Card.module.css';

export const getCardClassNames = ({
  onClick,
  disabled,
  border,
}: Pick<CardProps, 'onClick' | 'disabled' | 'border'>) => {
  return [
    styles.container,
    onClick && !disabled && styles.clickable,
    disabled && styles.disabled,
    border === 'dark' && styles.darkBorder,
    border === 'none' && styles.noBorder,
  ]
    .filter(Boolean)
    .join(' ');
};
