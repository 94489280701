export const Checkmark = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.30051 9.29631C4.06039 9.29631 3.83228 9.20031 3.66419 9.03231L0.266501 5.63631C-0.0816723 5.28831 -0.0816723 4.71231 0.266501 4.36431C0.614675 4.01631 1.19096 4.01631 1.53914 4.36431L4.30051 7.12431L10.4716 0.956313C10.8198 0.608313 11.396 0.608313 11.7442 0.956313C12.0924 1.30431 12.0924 1.88031 11.7442 2.22831L4.93683 9.03231C4.76874 9.20031 4.54063 9.29631 4.30051 9.29631Z"
        fill="currentColor"
      />
    </svg>
  );
};
