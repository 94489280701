export const ShootingStar = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5267 1.76019L10.48 4.28686C10.4734 4.63353 10.6934 5.09353 10.9734 5.30019L12.6267 6.55353C13.6867 7.35353 13.5134 8.33353 12.2467 8.73353L10.0934 9.40686C9.73335 9.52019 9.35335 9.91353 9.26002 10.2802L8.74669 12.2402C8.34002 13.7869 7.32668 13.9402 6.48668 12.5802L5.31335 10.6802C5.10002 10.3335 4.59335 10.0735 4.19335 10.0935L1.96668 10.2069C0.373351 10.2869 -0.0799823 9.36686 0.960018 8.15353L2.28002 6.62019C2.52668 6.33353 2.64002 5.80019 2.52668 5.44019L1.84668 3.28019C1.45335 2.01353 2.16002 1.31353 3.42002 1.72686L5.38668 2.37353C5.72002 2.48019 6.22002 2.40686 6.50002 2.20019L8.55335 0.720192C9.66669 -0.0731414 10.5534 0.393525 10.5267 1.76019Z"
        fill="currentColor"
      />
      <path
        d="M13.2935 12.6464L11.2735 10.6264C11.0802 10.4331 10.7602 10.4331 10.5669 10.6264C10.3735 10.8198 10.3735 11.1398 10.5669 11.3331L12.5869 13.3531C12.6869 13.4531 12.8135 13.4998 12.9402 13.4998C13.0669 13.4998 13.1935 13.4531 13.2935 13.3531C13.4869 13.1598 13.4869 12.8398 13.2935 12.6464Z"
        fill="currentColor"
      />
    </svg>
  );
};
