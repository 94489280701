import { ModuleFeedbackType } from '.';

interface ModuleFeedbackText {
  title: string;
  titleKey: string;
  description: string;
  descriptionKey: string;
  buttonText: string;
  buttonTextKey: string;
}

export const getModuleFeedbackText = (
  type: ModuleFeedbackType,
): ModuleFeedbackText => {
  switch (type) {
    case 'moduleCompleted':
      return {
        title: 'Great job!',
        titleKey: 'module_feedback_moduleCompleted_title',
        description: "You've nailed this module and are ready for the next one",
        descriptionKey: 'module_feedback_moduleCompleted_description',
        buttonText: 'Next module',
        buttonTextKey: 'module_feedback_moduleCompleted_button',
      };
    case 'courseCompleted':
      return {
        title: 'Great job!',
        titleKey: 'module_feedback_courseCompleted_title',
        description: "You've nailed this module and completed the course",
        descriptionKey: 'module_feedback_courseCompleted_description',
        buttonText: 'Finish course',
        buttonTextKey: 'module_feedback_courseCompleted_button',
      };
    case 'moduleNotCompleted':
      return {
        title: "You're almost there!",
        titleKey: 'module_feedback_moduleNotCompleted_title',
        description: 'Practice this module to get a perfect score',
        descriptionKey: 'module_feedback_moduleNotCompleted_description',
        buttonText: 'Restart',
        buttonTextKey: 'module_feedback_moduleNotCompleted_button',
      };
    case 'none':
      throw new Error('There should not be any module feedback');
  }
};
