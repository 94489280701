import axios, {
  AxiosError,
  AxiosInstance,
  Method,
  AxiosRequestConfig,
} from 'axios';

export interface RequestParams {
  method: Method;
  resourcePath: string;
  resourceId?: string;
  data?: object;
  isBufferResponse?: boolean;
  queryParams?: object;
  customHeaders?: any;
}

export interface ApiOptions extends Partial<AxiosRequestConfig> {
  baseUrl: string;
}

export class ApiRequest {
  private axiosInstance: AxiosInstance;

  constructor(options: ApiOptions) {
    const axiosConfig: AxiosRequestConfig = {
      withCredentials: true,
      ...options,
      baseURL: options.baseUrl,
    };

    this.axiosInstance = axios.create(axiosConfig);

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      this.handleErrorResponse,
    );
  }

  private handleErrorResponse = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
  };

  request = async <T>(params: RequestParams): Promise<T> => {
    const {
      method,
      resourcePath,
      resourceId,
      data,
      isBufferResponse = false,
      customHeaders,
      queryParams,
    } = params;

    const url = resourceId ? `${resourcePath}/${resourceId}` : resourcePath;

    const response = await this.axiosInstance.request<T>({
      method,
      url,
      data,
      params: queryParams,
      headers: customHeaders,
      responseType: isBufferResponse ? 'arraybuffer' : 'json',
    });

    return response.data;
  };
}
