import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { UIState } from './types';

const initialState: UIState = {
  isLoading: false,
  isMenuOpen: false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.isMenuOpen = action.payload;
    },
    toggleMenuOpen: (state, action: PayloadAction<boolean | undefined>) => {
      state.isMenuOpen = action.payload ?? !state.isMenuOpen;
    },
  },
});

// export the reducers
export const { setLoading, toggleMenuOpen } = uiSlice.actions;

export const selectLoadingState = (state: RootState) => state.ui.isLoading;
export const selectIsMenuOpen = (state: RootState) => state.ui.isMenuOpen;

export default uiSlice.reducer;
