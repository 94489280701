export const BustInSilhouette = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="76"
      height="75"
      viewBox="0 0 76 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.3252 47.3287C51.2034 47.3287 62.0752 49.4199 62.0752 57.4961C62.0752 65.5723 51.1337 67.5937 38.3252 67.5937C25.447 67.5937 14.5752 65.4994 14.5752 57.4264C14.5752 49.3502 25.5136 47.3287 38.3252 47.3287ZM38.3252 8.21875C47.048 8.21875 54.0402 15.2082 54.0402 23.9276C54.0402 32.647 47.048 39.6365 38.3252 39.6365C29.6024 39.6365 22.6102 32.647 22.6102 23.9276C22.6102 15.2082 29.6024 8.21875 38.3252 8.21875Z"
        fill="currentColor"
        fillOpacity="0.59"
      />
    </svg>
  );
};
