import { createContext, useContext } from 'react';
import { QueuedSnackParams, ShowSnackOptions } from './ModalsProvider.helpers';

export type ModalsContextType = {
  showSnack: (
    snackProps: QueuedSnackParams,
    options?: ShowSnackOptions,
  ) => void;
};

const modalsContextInitialValue: ModalsContextType = {
  showSnack: (snackProps: QueuedSnackParams, options?: ShowSnackOptions) =>
    undefined,
};

export const modalsContext = createContext(modalsContextInitialValue);

export const Modals = {
  ShowSnack: modalsContextInitialValue.showSnack,
};

export const useSnack = () => {
  const { showSnack } = useContext(modalsContext);

  return {
    showSnack,
  };
};
