export const Document = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666504 2.33334C0.666504 1.41477 1.4146 0.666672 2.33317 0.666672H12.4873C13.4059 0.666672 14.154 1.41477 14.154 2.33334V11.9167C14.154 12.3769 13.7809 12.75 13.3207 12.75C12.8604 12.75 12.4873 12.3769 12.4873 11.9167V2.33334H2.33317V13.5833C2.33317 14.7356 3.26424 15.6667 4.4165 15.6667H15.4331C15.8934 15.6667 16.2665 16.0398 16.2665 16.5C16.2665 16.9602 15.8934 17.3333 15.4331 17.3333H4.4165C2.34377 17.3333 0.666504 15.6561 0.666504 13.5833V2.33334Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3206 7.47514C13.7809 7.47514 14.154 7.84824 14.154 8.30848V14.6043C14.154 15.1898 14.6308 15.6667 15.2164 15.6667C15.8021 15.6667 16.279 15.1898 16.279 14.6043V9.14181H15.7123C15.2521 9.14181 14.879 8.76871 14.879 8.30848C14.879 7.84824 15.2521 7.47514 15.7123 7.47514H16.279C17.1975 7.47514 17.9456 8.22324 17.9456 9.14181V14.6043C17.9456 16.1104 16.7225 17.3333 15.2164 17.3333C13.7104 17.3333 12.4873 16.1104 12.4873 14.6043V8.30848C12.4873 7.84824 12.8604 7.47514 13.3206 7.47514Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.39551 5.77083C3.39551 5.3106 3.7686 4.9375 4.22884 4.9375H6.34971C6.80995 4.9375 7.18304 5.3106 7.18304 5.77083C7.18304 6.23107 6.80995 6.60417 6.34971 6.60417H4.22884C3.7686 6.60417 3.39551 6.23107 3.39551 5.77083Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.39551 8.66675C3.39551 8.20652 3.7686 7.83342 4.22884 7.83342H10.2539C10.7141 7.83342 11.0872 8.20652 11.0872 8.66675C11.0872 9.12699 10.7141 9.50009 10.2539 9.50009H4.22884C3.7686 9.50009 3.39551 9.12699 3.39551 8.66675Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.39551 11.5627C3.39551 11.1024 3.7686 10.7293 4.22884 10.7293H10.3622C10.8225 10.7293 11.1955 11.1024 11.1955 11.5627C11.1955 12.0229 10.8225 12.396 10.3622 12.396H4.22884C3.7686 12.396 3.39551 12.0229 3.39551 11.5627Z"
        fill="currentColor"
      />
    </svg>
  );
};
