export const GlobalSearch = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.08 41.52L42.64 40.08C43.38 38.96 43.82 37.6 43.82 36.16C43.82 32.2 40.62 29 36.66 29C32.7 29 29.5 32.2 29.5 36.16C29.5 40.12 32.7 43.32 36.66 43.32C38.12 43.32 39.46 42.88 40.58 42.14L42.02 43.58C42.3 43.86 42.68 44 43.04 44C43.42 44 43.78 43.86 44.06 43.58C44.64 43 44.64 42.08 44.08 41.52Z"
        fill="currentColor"
      />
      <path
        opacity="0.4"
        d="M4.55954 29.26C4.55954 29.32 4.51953 29.4 4.51953 29.46C6.35953 33.14 9.35955 36.16 13.0396 37.98C13.0996 37.98 13.1795 37.94 13.2395 37.94C12.5595 35.62 12.0395 33.24 11.6595 30.86C9.25955 30.46 6.87954 29.94 4.55954 29.26Z"
        fill="currentColor"
      />
      <path
        opacity="0.4"
        d="M38.6402 12.8599C36.7602 8.91986 33.5802 5.73988 29.6602 3.87988C30.3802 6.25988 30.9802 8.69989 31.3802 11.1399C33.8202 11.5399 36.2602 12.1199 38.6402 12.8599Z"
        fill="currentColor"
      />
      <path
        opacity="0.4"
        d="M4.36035 12.86C6.76035 12.14 9.20038 11.5401 11.6404 11.1401C12.0404 8.76008 12.5404 6.40008 13.2204 4.08008C13.1604 4.08008 13.0804 4.04004 13.0204 4.04004C9.26035 5.90004 6.20035 9.04005 4.36035 12.86Z"
        fill="currentColor"
      />
      <path
        opacity="0.4"
        d="M28.1404 10.72C27.6604 8.11997 27.0604 5.52 26.2004 3C26.1604 2.86 26.1604 2.74002 26.1404 2.58002C24.6604 2.22002 23.1004 1.97998 21.5004 1.97998C19.8804 1.97998 18.3404 2.20002 16.8404 2.58002C16.8204 2.72002 16.8404 2.84 16.8004 3C15.9604 5.52 15.3404 8.11997 14.8604 10.72C19.2804 10.24 23.7204 10.24 28.1404 10.72Z"
        fill="currentColor"
      />
      <path
        opacity="0.4"
        d="M11.2205 14.3599C8.60046 14.8399 6.02046 15.4399 3.50046 16.2999C3.36046 16.3399 3.24044 16.3399 3.08044 16.3599C2.72044 17.8399 2.48047 19.3999 2.48047 20.9999C2.48047 22.6199 2.70044 24.1599 3.08044 25.6599C3.22044 25.6799 3.34046 25.6599 3.50046 25.6999C6.02046 26.5399 8.60046 27.1599 11.2205 27.6399C10.7405 23.2199 10.7405 18.7799 11.2205 14.3599Z"
        fill="currentColor"
      />
      <path
        opacity="0.4"
        d="M39.8997 16.3599C39.7597 16.3599 39.6398 16.3399 39.4798 16.2999C36.9598 15.4599 34.3598 14.8399 31.7598 14.3599C32.2598 18.7799 32.2598 23.2199 31.7598 27.6199C34.3598 27.1399 36.9598 26.5399 39.4798 25.6799C39.6198 25.6399 39.7397 25.6599 39.8997 25.6399C40.2597 24.1399 40.4998 22.5999 40.4998 20.9799C40.4998 19.3999 40.2797 17.8599 39.8997 16.3599Z"
        fill="currentColor"
      />
      <path
        opacity="0.4"
        d="M14.8604 31.28C15.3404 33.9 15.9404 36.48 16.8004 39C16.8404 39.14 16.8204 39.26 16.8404 39.42C18.3404 39.78 19.8804 40.02 21.5004 40.02C23.1004 40.02 24.6604 39.8 26.1404 39.42C26.1604 39.28 26.1604 39.16 26.2004 39C27.0404 36.48 27.6604 33.9 28.1404 31.28C25.9404 31.52 23.7204 31.7 21.5004 31.7C19.2804 31.68 17.0604 31.52 14.8604 31.28Z"
        fill="currentColor"
      />
      <path
        opacity="0.4"
        d="M14.4002 13.9C13.8002 18.62 13.8002 23.3799 14.4002 28.1199C19.1202 28.7199 23.8802 28.7199 28.6202 28.1199C29.2202 23.3999 29.2202 18.64 28.6202 13.9C23.8802 13.3 19.1202 13.3 14.4002 13.9Z"
        fill="currentColor"
      />
    </svg>
  );
};
