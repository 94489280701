export const GreatBritainFlag = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_271_629)">
        <rect width="20" height="20" rx="10" fill="#22438B" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.02137 0H-4V3.33333L21.0052 20L24 20V16.6667L-1.02137 0Z"
          fill="white"
        />
        <path
          d="M-3.00654 0L24 18.0472V20H23.0298L-4 1.93408V0H-3.00654Z"
          fill="#C8102E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.3333 0H24V3.33333C24 3.33333 6.67974 14.4374 -1.33333 20H-4V16.6667L21.3333 0Z"
          fill="white"
        />
        <path
          d="M24 0H23.0957L-4 18.0628V20H-3.00654L24 1.94868V0Z"
          fill="#C8102E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.18277 0H13.8405V6.16909H24V13.8268H13.8405V20H6.18277V13.8268H-4V6.16909H6.18277V0Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.78947 0H12.2105V7.69231H24V12.3077H12.2105V20H7.78947V12.3077H-4V7.69231H7.78947V0Z"
          fill="#C8102E"
        />
      </g>
      <rect
        x="0.5"
        y="0.5"
        width="19"
        height="19"
        rx="9.5"
        stroke="black"
        strokeOpacity="0.1"
      />
      <defs>
        <clipPath id="clip0_271_629">
          <rect width="20" height="20" rx="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
