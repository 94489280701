export const ChevronUp = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.29104 4.29257C9.92144 4.92241 9.47537 6 8.58425 6H1.41268C0.521988 6 0.0757232 4.92331 0.705251 4.29321L4.28781 0.707426C4.67815 0.316725 5.31132 0.31644 5.70202 0.706789L9.29104 4.29257Z"
        fill="currentColor"
      />
    </svg>
  );
};
