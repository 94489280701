import React, {
  PropsWithChildren,
  ReactNode,
  createContext,
  useContext,
} from 'react';
import featureFlags from './featureFlags';

export type FeatureFlagsType = {
  enablePasswordResetWithoutRedirect: boolean;
  enableSsoLogin: boolean;
};

type FeatureFlagProviderProps = {
  value?: FeatureFlagsType;
  children: ReactNode;
};

const FeatureFlagContext = createContext<FeatureFlagsType>(featureFlags);

export const FeatureFlagProvider: React.FC<
  PropsWithChildren<FeatureFlagProviderProps>
> = ({ children, value }) => {
  return (
    <FeatureFlagContext.Provider value={value || featureFlags}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlags = () => useContext(FeatureFlagContext);
