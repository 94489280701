import { ReactElement } from 'react';
import StyledClass from './SvgIcon.module.css';

interface SvgIconProps {
  icon: ReactElement;
  onClick?: () => void;
  disabled?: boolean;
}

export const SvgIcon = (props: SvgIconProps) => {
  const { icon, onClick, disabled } = props;
  return (
    <div
      className={`${StyledClass.img} ${onClick ? StyledClass.clickable : ''} ${disabled ? StyledClass.disabled : ''}`}
      onClick={disabled ? undefined : onClick}
    >
      {icon}
    </div>
  );
};
