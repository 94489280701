import { Button, Card, InputField, Text } from '../../atoms';
import styles from './ForgotPassword.module.css';
import { useNavigate } from 'react-router-dom';
import { LoginHeader } from '../LoginHeader';
import { useState } from 'react';
import { Utils } from '@mono/common';
import { Icons } from '../../assets/icons';
import { getForgotPasswordInstructionsSentErrorKey } from './ForgotPassword.helpers';
import { useSnack } from '../../providers';

interface ForgotPasswordProps {
  onSendInstructions: (email: string) => void;
}

export const ForgotPassword = ({ onSendInstructions }: ForgotPasswordProps) => {
  const navigate = useNavigate();
  const { showSnack } = useSnack();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>();
  const isEmailValid = email.length && !error;

  const goBackToLogin = () => {
    navigate('/login');
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);

    if (!value.length || Utils.isValidEmail(value)) {
      setError(undefined);
    }
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const isValidEmail = Utils.isValidEmail(value);
    setError(isValidEmail || !value ? undefined : 'incorrect_email');
  };

  const onSendInstructionsActon = async () => {
    if (!isEmailValid) return;
    try {
      setLoading(true);
      await onSendInstructions(email);
    } catch (error: any) {
      const errorKey = getForgotPasswordInstructionsSentErrorKey(error);
      showSnack({
        textKey: errorKey,
        type: 'error',
        icon: <Icons.CrossRound />,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <Card>
        <div className={styles.childContainer}>
          <LoginHeader />

          <Text
            text="Enter your user email address for password recovery. Follow the instructions to reset your password."
            textKey="forgotPassword_info"
            fontSize="bodyLarge"
            fontWeight="regular"
          />

          <InputField
            label="Email Address"
            labelKey="login_email_label"
            helperText="Enter email"
            helperTextKey="login_email_helper"
            type="email"
            value={email}
            onChange={onChange}
            onBlur={onBlur}
            errorTextKey={error}
          />

          <div className={`${styles.button} ${styles.firstButton}`}>
            <Button
              text="Send instructions"
              textKey="forgotPassword_send_instructions"
              onClick={onSendInstructionsActon}
              size="full"
              icon={
                loading ? (
                  <Icons.Loader className={styles.loaderIcon} />
                ) : undefined
              }
              disabled={!isEmailValid || loading}
            />
          </div>
          <div className={styles.button}>
            <Button
              text="Back to login"
              textKey="forgotPassword_to_login"
              onClick={goBackToLogin}
              size="full"
              type="secondary"
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
