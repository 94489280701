import { ReactElement } from 'react';
import { Button, ButtonProps, ButtonType } from '../Button';
import styles from './IconButton.module.css';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '../MenuItem';

interface IconButtonProps extends Omit<ButtonProps, 'customContent' | 'type'> {
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  className?: string;
  textClassName?: string;
  bottomBorder?: boolean;
  justifyContent?: 'between' | 'center' | 'start' | 'end';
  type?: ButtonType | 'menu';
}

export const IconButton = (props: IconButtonProps) => {
  const {
    leftIcon,
    rightIcon,
    justifyContent = 'between',
    className,
    textClassName,
    bottomBorder,
    type,
    ...buttonProps
  } = props;
  const { t } = useTranslation();
  const { textKey = '', text = '' } = buttonProps;
  const containerClass = styles[justifyContent];
  const haveText = text || textKey;

  const customContent = (
    <div
      className={`${styles.container} ${bottomBorder ? styles.bottomBorder : ''} ${containerClass} ${className ?? ''}`}
    >
      {leftIcon}
      {haveText && (
        <span className={textClassName ?? ''}>
          {textKey ? t(textKey, text) : text || text}
        </span>
      )}
      {rightIcon}
    </div>
  );

  return type === 'menu' ? (
    <MenuItem {...buttonProps}>{customContent}</MenuItem>
  ) : (
    <Button {...buttonProps} type={type} customContent={customContent} />
  );
};
