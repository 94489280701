import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AuthState } from './types';
import { RootState } from '../../../store';
import { IProfile } from '../../../utils/interfaces/core-api';

const initialState: AuthState = {
  user: null,
  isLoading: { login: false },
  error: null,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IProfile>) => {
      state.user = { ...state.user, ...action.payload };
    },
    resetUser: (state) => {
      state.user = null;
    },
    setIsLoginLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = {
        ...state.isLoading,
        login: action.payload,
      };
    },
    setIsError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

// export the reducers
export const { setUser, setIsLoginLoading, resetUser, setIsError } =
  loginSlice.actions;

export const selectLoginIsLoading = (state: RootState) =>
  state.user.isLoading.login;
export const selectLoginError = (state: RootState) => state.user.error;
export const selectUser = (state: RootState) => state.user.user;

export default loginSlice.reducer;
