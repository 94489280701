export const Hourglass = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3.16668V1.83334H12V3.16668H11.3333V5.16668C11.3333 7.00762 9.84093 8.50001 8 8.50001C6.15905 8.50001 4.66667 7.00762 4.66667 5.16668V3.16668H4ZM8 8.50001C9.84093 8.50001 11.3333 9.99241 11.3333 11.8333V13.8333H12V15.1667H4V13.8333H4.66667V11.8333C4.66667 9.99241 6.15905 8.50001 8 8.50001ZM10 3.16668H6V5.16668C6 6.27124 6.8954 7.16668 8 7.16668C9.1046 7.16668 10 6.27124 10 5.16668V3.16668ZM6 13.8333V11.8333C6 10.7287 6.8954 9.83334 8 9.83334C9.1046 9.83334 10 10.7287 10 11.8333V13.8333H6ZM7.33333 4.50001H8.66667V5.16668C8.66667 5.53486 8.3682 5.83334 8 5.83334C7.6318 5.83334 7.33333 5.53486 7.33333 5.16668V4.50001Z"
        fill="currentColor"
      />
    </svg>
  );
};
