import { useTranslation } from 'react-i18next';
import { Images } from '../../assets/images';
import { Badge, Button, Card, Text } from '../../atoms';
import { Row } from '../../atoms/Row';
import { getTranslationKey } from '../../i18n';
import {
  CourseStatus,
  CourseType,
  UserCourseResult,
} from '../../utils/interfaces';
import { InfoSet } from '../InfoSet';
import styles from './CourseCard.module.css';

interface CourseCardProps {
  course: UserCourseResult;
  onStartCourse: () => void;
}

export const CourseCard = (props: CourseCardProps) => {
  const { course, onStartCourse } = props;
  const { t } = useTranslation();

  const getInfoSet = () => {
    return (
      <InfoSet
        infoFields={[
          {
            text: t(
              getTranslationKey(
                course.moduleIds.length,
                'course_module_count_singular',
                'course_module_count_plural',
              ),
              { count: course.moduleIds.length },
            ),
            icon: 'document',
          },
          {
            text: t(
              getTranslationKey(
                course.totalDurationMin,
                'course_duration_count_singular',
                'course_duration_count_plural',
              ),
              { count: course.totalDurationMin },
            ),
            icon: 'clock',
          },
        ]}
      />
    );
  };

  const getButtonTextKey = (status: CourseStatus) => {
    switch (status) {
      case CourseStatus.Ongoing:
        return 'course_continue_button';
      case CourseStatus.Completed:
        return 'course_review_button';
      case CourseStatus.Todo:
      default:
        return 'course_start_button';
    }
  };

  return (
    <div className={styles.card}>
      <Card
        image={course.coverImageUrl ?? Images.PersonWithComputer}
        onClick={onStartCourse}
        disabled={!course.enabled}
      >
        <div className={styles.topRightContainer}>
          {course.type === CourseType.Premium && (
            <div className={styles.badge}>
              <Badge type={course.type} />
            </div>
          )}
          <div className={styles.badge}>
            <Badge type={course.status} />
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.textContainer}>
            <Row>
              {!!course.tags?.length ? (
                <>
                  {course.tags.map((tag) => (
                    <Badge type={tag} key={tag} />
                  ))}
                </>
              ) : undefined}
              <div className={styles.title}>
                <Text
                  text={course.title}
                  textKey={course.titleKey}
                  fontSize="h3"
                  fontWeight="bold"
                  truncate="twoLines"
                />
              </div>
            </Row>
            <div className={styles.description}>
              <Text
                text={course.description}
                textKey={course.descriptionKey}
                fontSize="body"
                fontWeight="regular"
                truncate="tenLines"
              />
            </div>
            <div className={styles.infoSetContainer}>{getInfoSet()}</div>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              textKey={getButtonTextKey(course.status)}
              size="full"
              type="primary"
            ></Button>
          </div>
        </div>
      </Card>
    </div>
  );
};
