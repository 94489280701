export const EyeCrossed = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8788 8.25784C17.2886 8.46715 17.4513 8.96911 17.2419 9.379C15.6246 12.5462 12.5728 14.7499 8.99978 14.7499C7.45142 14.7499 5.99304 14.3333 4.70714 13.5985C4.30754 13.3701 4.16871 12.8611 4.39705 12.4615C4.62539 12.0619 5.13444 11.9231 5.53403 12.1514C6.58147 12.7499 7.75647 13.0833 8.99978 13.0833C11.8434 13.0833 14.3749 11.3288 15.7576 8.62103C15.9669 8.21114 16.4689 8.04854 16.8788 8.25784Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.11814 8.26335C1.52722 8.05245 2.0298 8.2131 2.2407 8.62217C2.68588 9.48566 3.24756 10.256 3.90004 10.9047C4.22642 11.2292 4.22796 11.7568 3.90347 12.0832C3.57899 12.4096 3.05135 12.4112 2.72497 12.0867C1.94411 11.3104 1.28081 10.3974 0.759321 9.38591C0.548422 8.97683 0.709073 8.47425 1.11814 8.26335Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1555 4.71205C12.3835 4.31225 12.8924 4.17297 13.2922 4.40095C14.9568 5.35017 16.3291 6.82992 17.243 8.62617C17.4517 9.03637 17.2883 9.53808 16.8781 9.74678C16.4679 9.95548 15.9662 9.79213 15.7575 9.38193C14.9797 7.85318 13.827 6.62454 12.4666 5.84876C12.0668 5.62078 11.9275 5.11185 12.1555 4.71205Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00002 4.91654C6.15641 4.91654 3.6249 6.67116 2.24219 9.37898C2.03288 9.78887 1.53092 9.95148 1.12103 9.74217C0.711143 9.53287 0.548538 9.03091 0.757843 8.62102C2.37513 5.45384 5.42695 3.24988 9.00002 3.24988C9.66249 3.24988 10.3042 3.32869 10.924 3.47101C11.3725 3.57401 11.6527 4.02114 11.5497 4.46971C11.4467 4.91827 10.9995 5.1984 10.551 5.0954C10.0458 4.97939 9.52921 4.91654 9.00002 4.91654Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.8899 6.93146C10.2153 6.60602 10.743 6.60602 11.0684 6.93146C11.5976 7.4606 11.925 8.19106 11.925 9C11.925 10.6144 10.6145 11.925 9.00002 11.925C8.19093 11.925 7.46061 11.5974 6.93158 11.0683C6.60614 10.7429 6.60614 10.2153 6.93158 9.88983C7.25701 9.56439 7.78465 9.56439 8.11009 9.88983C8.33939 10.1191 8.65078 10.2583 9.00002 10.2583C9.69392 10.2583 10.2583 9.69396 10.2583 9C10.2583 8.65061 10.1191 8.33916 9.8899 8.10997C9.56446 7.78453 9.56446 7.25689 9.8899 6.93146Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.089 0.910377C17.4146 1.23563 17.4149 1.76327 17.0897 2.08889L13.4688 5.71377C13.1436 6.03938 12.6159 6.03968 12.2903 5.71442C11.9647 5.38916 11.9644 4.86153 12.2896 4.53591L15.9105 0.911031C16.2358 0.585413 16.7634 0.585121 17.089 0.910377Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0684 6.93145C11.3938 7.25688 11.3939 7.78452 11.0684 8.10996L8.11009 11.0683C7.78466 11.3938 7.25702 11.3938 6.93158 11.0683C6.60614 10.7429 6.60614 10.2153 6.93157 9.88983L9.8899 6.93146C10.2153 6.60602 10.743 6.60602 11.0684 6.93145Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.7099 12.2899C6.03534 12.6153 6.03534 13.1429 5.7099 13.4684L2.08909 17.0892C1.76366 17.4146 1.23602 17.4146 0.910582 17.0892C0.585145 16.7637 0.585145 16.2361 0.910582 15.9107L4.53139 12.2899C4.85683 11.9644 5.38446 11.9644 5.7099 12.2899Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.11018 9.88982C8.43562 10.2152 8.43563 10.7429 8.1102 11.0683L5.7102 13.4684C5.38477 13.7939 4.85713 13.7939 4.53169 13.4685C4.20624 13.143 4.20623 12.6154 4.53166 12.2899L6.93167 9.88984C7.2571 9.5644 7.78473 9.56439 8.11018 9.88982Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4691 4.53609C13.7943 4.86181 13.7938 5.38945 13.4681 5.71461L11.0681 8.11044C10.7424 8.43559 10.2147 8.43513 9.88956 8.10941C9.56441 7.78369 9.56487 7.25606 9.89059 6.9309L12.2906 4.53507C12.6163 4.20992 13.1439 4.21037 13.4691 4.53609Z"
        fill="currentColor"
      />
    </svg>
  );
};
