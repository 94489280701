import { ButtonSize, ButtonType } from '.';
import StyledClass from './Button.module.css';

export const getButtonClassName = (type: ButtonType, size: ButtonSize) => {
  let className = StyledClass.button;
  if (size === 'full') {
    className += ` ${StyledClass.full}`;
  }
  if (size === 'full-auto') {
    className += ` ${StyledClass.fullAuto}`;
  }
  if (type === 'bareAttention') {
    className += ` ${StyledClass.bare} ${StyledClass.attention}`;
  } else {
    className += ` ${StyledClass[type]}`;
  }
  return className;
};
