import { AppLanguage } from '@mono/common';
import { Provider } from '../../atoms/VideoEmbedded';
import { Language } from '../../utils/interfaces';

export const getVideoProvider = (videoUrl: string): Provider | undefined => {
  if (/youtube\.com|youtu\.be/.test(videoUrl)) {
    return 'youtube';
  } else if (/loom\.com/.test(videoUrl)) {
    return 'loom';
  }
};

export const convertAppLanguageToCourseLanguage = (
  appLanguage: AppLanguage,
): Language => {
  switch (appLanguage) {
    case AppLanguage.en:
      return Language.en;
    case AppLanguage.nl:
      return Language.nl;
    case AppLanguage.se:
      return Language.se;
    default:
      return Language.se; // Default to se since most of our users are Swedish
  }
};
