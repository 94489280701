import { useEffect, useState } from 'react';
import { Snackbar } from '../../../../atoms/Snackbar';
import { QueuedSnack } from '../../ModalsProvider.helpers';
import styles from './QueuedSnackbar.module.css';

type QueuedSnackbarProps = {
  queuedSnack: QueuedSnack;
};

export const QueuedSnackbar = ({ queuedSnack }: QueuedSnackbarProps) => {
  const [isShown, setIsShown] = useState(true);

  useEffect(() => {
    queuedSnack.listeners.willBeClosed = () => setIsShown(false);
  }, []);

  const onSnackbarClose = () => {
    setIsShown(false);
    setTimeout(() => {
      queuedSnack.methods.close();
    }, 500);
  };

  return (
    <div className={`${styles.container} ${isShown ? '' : styles.closing}`}>
      <Snackbar
        {...queuedSnack.snackData.snackProps}
        isShown={isShown}
        onClose={onSnackbarClose}
      />
    </div>
  );
};
