import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ModalsProvider } from './providers/ModalsProvider';
import App from './App';
import { Loader } from './atoms/Loader';
import './i18n';
import { store } from './store';
import './styles/fonts.css';
import './styles/reset.css';
import './styles/variables.css';

const root = ReactDOM.createRoot(document.getElementById('root')!);
const isDevMode = process.env.NODE_ENV === 'development'; // Using this to avoid empty useEffect() is called twice in DEV mode https://stackoverflow.com/a/71982736
root.render(
  isDevMode ? (
    <Provider store={store}>
      <ModalsProvider>
        <Loader />
        <App />
      </ModalsProvider>
    </Provider>
  ) : (
    <React.StrictMode>
      <Provider store={store}>
        <ModalsProvider>
          <Loader />
          <App />
        </ModalsProvider>
      </Provider>
    </React.StrictMode>
  ),
);
