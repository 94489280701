export const CheckmarkRoundFilled = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill="currentColor"
      />
      <path
        d="M8.58043 13.5801C8.38043 13.5801 8.19043 13.5001 8.05043 13.3601L5.22043 10.5301C4.93043 10.2401 4.93043 9.7601 5.22043 9.4701C5.51043 9.1801 5.99043 9.1801 6.28043 9.4701L8.58043 11.7701L13.7204 6.6301C14.0104 6.3401 14.4904 6.3401 14.7804 6.6301C15.0704 6.9201 15.0704 7.4001 14.7804 7.6901L9.11043 13.3601C8.97043 13.5001 8.78043 13.5801 8.58043 13.5801Z"
        fill="currentColor"
      />
    </svg>
  );
};
