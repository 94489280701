import styles from './LoginHeader.module.css';
import { AcademyLogo, LanguageSelector } from '../../molecules';

export const LoginHeader = () => {
  return (
    <div className={styles.header}>
      <AcademyLogo type="dark" />
      <div className={styles.languageSelector}>
        <LanguageSelector hasBorder={false} expansionDirection="up" />
      </div>
    </div>
  );
};
