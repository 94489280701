import { Routes, Route } from 'react-router-dom';
import {
  CourseCompletionPage,
  CourseModulePage,
  CourseModuleRedirect,
  CoursesPage,
  ForgotPasswordPage,
  LoginPage,
  NotFoundPage,
} from './pages';
import { RequireAuth } from './requireAuth';
import { RecoverPasswordPage } from './pages/RecoverPasswordPage';

export const public_routes = [
  {
    path: '/recoverPassword/:userId/:resetToken',
    element: <RecoverPasswordPage />,
  },
  { path: '/forgotPassword', element: <ForgotPasswordPage /> },
  { path: '/login', element: <LoginPage mode="login" /> },
  { path: '/sso', element: <LoginPage mode="sso" /> },
  { path: '/404', element: <NotFoundPage /> },
  { path: '/*', element: <NotFoundPage /> },
];
export const auth_routes = [
  { path: '/courses/:courseId/completed', element: <CourseCompletionPage /> },
  { path: '/courses/:courseId', element: <CourseModuleRedirect /> },
  {
    path: '/courses/:courseId/modules/:moduleId',
    element: <CourseModulePage />,
  },
  { path: '/courses', element: <CoursesPage /> },
  { path: '/', element: <CoursesPage /> },
];
export const reroutable_routes = [
  ...auth_routes.map((r) => r.path).filter((p) => p !== '/'),
  '/courses/:courseId',
];

export const AppRoutes = () => {
  return (
    <Routes>
      {public_routes.map((route, key) => (
        <Route
          key={`public-${key}`}
          path={route.path}
          element={route.element}
        />
      ))}
      {auth_routes.map((route, key) => (
        <Route
          key={`auth-${key}`}
          path={route.path}
          element={
            <RequireAuth currentPath={route.path}>{route.element}</RequireAuth>
          }
        />
      ))}
    </Routes>
  );
};
