import { PropsWithChildren, useState } from 'react';
import {
  Modals,
  modalsContext,
  ModalsContextType,
} from './ModalsProvider.provider';
import { createQueuedSnack, QueuedSnack } from './ModalsProvider.helpers';
import styles from './ModalsProvider.module.css';
import { QueuedSnackbar } from './components/QueuedSnackbar/QueuedSnackbar';

const Provider = modalsContext.Provider;

export const ModalsProvider = ({ children }: PropsWithChildren) => {
  const [snacksQueue, setSnacksQueue] = useState<QueuedSnack[]>([]);

  const removeQueuedSnack = (snackId: string) => {
    setSnacksQueue((snacksQueue) =>
      // timeout clearing logic happens inside ModalsProvider.helpers.ts
      snacksQueue.filter((qt) => qt.id !== snackId),
    );
  };

  const showSnack: ModalsContextType['showSnack'] = (
    snackProps,
    options = {},
  ) => {
    const queuedSnack = createQueuedSnack(
      removeQueuedSnack,
      snackProps,
      options,
    );
    setSnacksQueue((snacksQueue) => [...snacksQueue, queuedSnack]);
  };

  const onSnacksListOver = () => {
    snacksQueue.forEach((qt) => qt.methods.cancelClosing());
  };

  const value: ModalsContextType = {
    showSnack,
  };
  Modals.ShowSnack = showSnack;

  return (
    <Provider value={value}>
      {children}
      <div className={styles.modalsContainer}>
        <div className={styles.snacksList} onMouseEnter={onSnacksListOver}>
          {snacksQueue.map((snack) => (
            <QueuedSnackbar key={snack.id} queuedSnack={snack} />
          ))}
        </div>
      </div>
    </Provider>
  );
};
