import { useTranslation } from 'react-i18next';
import { SvgIcon } from '../SvgIcon';
import { ReactNode, useState } from 'react';
import { Icons } from '../../assets/icons';
import styles from './InputField.module.css';
import { Text } from '../Text';

interface InputFieldProps {
  label?: string;
  labelKey?: string;
  helperText: string;
  helperTextKey: string;
  type?: 'text' | 'email' | 'password' | 'search';
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  name?: string;
  value?: string;
  disabled?: boolean;
  errorText?: string;
  errorTextKey?: string;
}

const iconsMap: Omit<
  { [K in InputFieldProps['type'] as string]: ReactNode },
  'text'
> = {
  email: <Icons.Envelope className={styles.icon} />,
  password: <Icons.Lock className={styles.icon} />,
  search: (
    <Icons.MagnifyingGlass className={`${styles.icon} ${styles.mediumDark}`} />
  ),
};

export const InputField = (props: InputFieldProps) => {
  const {
    label,
    labelKey,
    helperText,
    helperTextKey,
    type = 'text',
    onChange,
    onBlur,
    name,
    value,
    disabled = false,
    errorText,
    errorTextKey,
  } = props;
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const renderInputField = () => {
    return (
      <div
        className={`${styles.container} ${disabled ? styles['disabled'] : ''} ${errorText || errorTextKey ? styles.errorField : ''}`}
      >
        {iconsMap[type]}
        <input
          className={styles.input}
          type={
            type === 'password' ? (showPassword ? 'text' : 'password') : type
          }
          placeholder={
            helperTextKey ? t(helperTextKey, helperText) : helperText
          }
          name={name}
          value={value}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
        />
        {type === 'password' && (
          <SvgIcon
            icon={
              showPassword ? (
                <Icons.EyeCrossed
                  className={`${styles.icon} ${styles.dark} `}
                />
              ) : (
                <Icons.Eye className={`${styles.icon} ${styles.dark} `} />
              )
            }
            onClick={() => setShowPassword(!showPassword)}
          />
        )}
      </div>
    );
  };

  return (
    <>
      {!!label && (
        <div className={styles.label}>
          <Text
            text={label}
            textKey={labelKey}
            fontSize="titleSmall"
            fontWeight="bold"
          />
        </div>
      )}
      {renderInputField()}
      {(errorText || errorTextKey) && (
        <div className={styles.errorText}>
          <Text
            text={errorText || 'Error'}
            textKey={errorTextKey}
            variant="danger"
            fontSize="titleSmall"
            fontWeight="medium"
          />
        </div>
      )}
    </>
  );
};
