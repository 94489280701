import { Icons } from '../../assets/icons';
import { Card, Text } from '../../atoms';
import { Row } from '../../atoms/Row';
import {
  MultipleChoiceAlternativeDto,
  QuestionDto,
} from '../../utils/interfaces';
import { Checkbox } from '../Checkbox';
import {
  getAnswerStatus,
  getIsAnswerCorrect,
  getNumberOfCorrectAlternatives,
  getChosenAlternativesOnCheckboxChange,
  getIsAlternativeCorrect,
} from './MultipleChoiceQuestion.helper';
import styles from './MultipleChoiceQuestion.module.css';

export interface MultipleChoiceQuestionProps {
  questionNumber: number;
  question: QuestionDto;
  isCorrectionMode: boolean;
  isDisabled?: boolean;
  chosenAnswers?: number[];
  onAnswerChange: (chosenAnswers: number[]) => void;
}

export const MultipleChoiceQuestion = (props: MultipleChoiceQuestionProps) => {
  const {
    questionNumber,
    question,
    isCorrectionMode,
    isDisabled = false,
    chosenAnswers: chosenAlternatives = [],
    onAnswerChange,
  } = props;

  const numberOfCorrectAlternatives = getNumberOfCorrectAlternatives(
    question.multipleChoiceAlternatives,
  );

  const handleCheckboxChange = (index: number, checked: boolean) => {
    const updatedAnswers = getChosenAlternativesOnCheckboxChange(
      numberOfCorrectAlternatives > 1,
      chosenAlternatives,
      index,
      checked,
    );
    onAnswerChange(updatedAnswers);
  };

  const isAnswerCorrect: boolean = getIsAnswerCorrect(
    chosenAlternatives,
    question.multipleChoiceAlternatives,
  );

  const answerStatus = getAnswerStatus(isCorrectionMode, isAnswerCorrect);
  return (
    <Card>
      <div
        className={`${styles.instructionContainer} ${answerStatus ? styles[answerStatus] : ''}`}
      >
        <Text
          text={
            numberOfCorrectAlternatives === 1
              ? 'Select one'
              : 'Select all that apply'
          }
          textKey={
            numberOfCorrectAlternatives === 1
              ? 'question_select_one'
              : 'question_select_multiple'
          }
          fontSize="body"
          fontWeight="medium"
        />
        {isCorrectionMode && (
          <>
            {isAnswerCorrect ? (
              <Icons.CheckmarkRound className={styles.icon} />
            ) : (
              <Icons.CrossRound className={styles.icon} />
            )}
          </>
        )}
      </div>
      <Row>
        <Text text={`${questionNumber}. `} fontSize="h3" fontWeight="bold" />
        <Text
          text={question.question}
          key={question.questionKey}
          fontSize="h3"
          fontWeight="bold"
        />
      </Row>
      {question.multipleChoiceAlternatives.map(
        (alternative: MultipleChoiceAlternativeDto, index: number) => (
          <div className={styles.checkbox} key={index}>
            <Checkbox
              key={index}
              text={alternative.alternative}
              textKey={alternative.alternativeKey}
              checked={chosenAlternatives?.includes(index) || false}
              isAlternativeCorrect={getIsAlternativeCorrect(
                alternative,
                isCorrectionMode,
              )}
              onChange={(checked) => handleCheckboxChange(index, checked)}
              isDisabled={isDisabled}
            />
          </div>
        ),
      )}
    </Card>
  );
};
