export const CheckmarkRound = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00033 2.33317C5.31843 2.33317 2.33366 5.31794 2.33366 8.99984C2.33366 12.6817 5.31843 15.6665 9.00033 15.6665C12.6822 15.6665 15.667 12.6817 15.667 8.99984C15.667 5.31794 12.6822 2.33317 9.00033 2.33317ZM0.666992 8.99984C0.666992 4.39746 4.39795 0.666504 9.00033 0.666504C13.6027 0.666504 17.3337 4.39746 17.3337 8.99984C17.3337 13.6022 13.6027 17.3332 9.00033 17.3332C4.39795 17.3332 0.666992 13.6022 0.666992 8.99984Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0396 5.97336C13.3648 6.29895 13.3646 6.82659 13.039 7.15187L8.59321 11.5935C8.26762 11.9188 7.73998 11.9185 7.4147 11.5929C7.08942 11.2674 7.08966 10.7397 7.41525 10.4144L11.8611 5.97281C12.1866 5.64752 12.7143 5.64777 13.0396 5.97336Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.93432 8.20501C5.24439 7.8649 5.77146 7.84055 6.11157 8.15063L8.56575 10.3881C8.90585 10.6981 8.9302 11.2252 8.62013 11.5653C8.31005 11.9054 7.78298 11.9298 7.44287 11.6197L4.9887 9.38226C4.64859 9.07219 4.62424 8.54511 4.93432 8.20501Z"
        fill="currentColor"
      />
    </svg>
  );
};
