import { ReactElement, useEffect, useState } from 'react';
import styles from './Snackbar.module.css';
import { Icons } from '../../assets/icons';
import { Text } from '../Text';

export type SnackbarType = 'success' | 'info' | 'error';

export interface SnackbarProps {
  icon?: ReactElement;
  text?: string;
  textKey?: string;
  type?: SnackbarType;
  isShown: boolean;
  onClose?: () => void;
}

export const Snackbar = (props: SnackbarProps) => {
  const { isShown = false, onClose } = props;
  const [initialProps, setInitialProps] = useState<SnackbarProps>(props); // This is necessary to avoid headache when parent components snackbar data is changed to undefined.
  const [isDisplayed, setIsDisplayed] = useState(isShown);
  const fadeOutAnimationActive = isDisplayed && !isShown;

  useEffect(() => {
    if (isShown) {
      setInitialProps(props);
      setIsDisplayed(true);
    } else {
      setTimeout(() => {
        setIsDisplayed(false);
      }, 500);
    }
  }, [isShown]);

  if (!isDisplayed) return null;

  return (
    <div
      className={`${styles.container} ${styles[initialProps.type ?? 'success']} ${fadeOutAnimationActive && styles.closed}`}
    >
      <div className={styles.innerRow}>
        {initialProps.icon}
        <Text
          textKey={initialProps.textKey}
          text={initialProps.text ?? ''}
          fontWeight="medium"
        />
      </div>
      {onClose && (
        <div className={styles.closeIconContainer} onClick={onClose}>
          <Icons.Cross className={styles.closeIcon} />
        </div>
      )}
    </div>
  );
};
