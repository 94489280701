import { QuestionDto } from '../../utils/interfaces';

export const getInitialQuizState = (questions: QuestionDto[]) => {
  return Array.from({ length: questions.length }, () => []);
};

export const isMissingAnswers = (quizState: number[][]) => {
  if (quizState.length === 0) {
    return true;
  }
  return quizState.some(
    (questionState) => !questionState || questionState.length === 0,
  );
};

export const getCorrectAnswers = (questions: QuestionDto[]): number[][] => {
  return questions.map((question) => {
    const result: number[] = [];
    question.multipleChoiceAlternatives.forEach(
      (alternative, alternativeIndex) => {
        if (alternative.isCorrect) {
          result.push(alternativeIndex);
        }
      },
    );
    return result;
  });
};

export const getNumberOfCorrectAnswers = (
  questions: QuestionDto[],
  quizState: number[][],
) => {
  const correctAnswerState: number[][] = getCorrectAnswers(questions);
  let correctCount = 0;
  for (let i = 0; i < correctAnswerState.length; i++) {
    const userAnswers = quizState[i] ?? [];
    const correctAnswers = correctAnswerState[i];
    if (arraysContainsSameElements(userAnswers, correctAnswers)) {
      correctCount++;
    }
  }
  return correctCount;
};

const arraysContainsSameElements = (
  arr1: number[],
  arr2: number[],
): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedArr1 = arr1.slice().sort((a, b) => a - b);
  const sortedArr2 = arr2.slice().sort((a, b) => a - b);
  return sortedArr1.every((value, index) => value === sortedArr2[index]);
};
