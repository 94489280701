import { UserCourseResult } from '../../utils/interfaces';

export const getEntryModuleId = (
  course: UserCourseResult,
): string | undefined => {
  if (!course.modules?.length) {
    return undefined;
  }

  const firstModuleNotCompleted = course.modules.find(
    (module) => !module.completed,
  );
  return firstModuleNotCompleted?._id ?? course.modules[0]._id;
};
