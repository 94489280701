import { MultipleChoiceAlternativeDto } from '../../utils/interfaces';

export const getIsAnswerCorrect = (
  chosenAlternatives: number[],
  alternatives: MultipleChoiceAlternativeDto[],
) => {
  const correctAlternatives: number[] = [];
  alternatives.forEach((alternative, index) => {
    if (alternative.isCorrect) {
      correctAlternatives.push(index);
    }
  });

  if (correctAlternatives.length !== chosenAlternatives.length) {
    return false;
  }

  return correctAlternatives.every((index) =>
    chosenAlternatives.includes(index),
  );
};

export const getAnswerStatus = (
  isCorrectionMode: boolean,
  isAnswerCorrect: boolean,
) => {
  if (!isCorrectionMode) {
    return undefined;
  }
  return isAnswerCorrect ? 'success' : 'danger';
};

export const getNumberOfCorrectAlternatives = (
  alternatives: MultipleChoiceAlternativeDto[],
) => {
  return alternatives.filter(
    (alternative: MultipleChoiceAlternativeDto) => alternative.isCorrect,
  ).length;
};

export const getChosenAlternativesOnCheckboxChange = (
  allowMultipleChoices: boolean,
  previousChosenAlternatives: number[],
  index: number,
  checked: boolean,
) => {
  if (allowMultipleChoices) {
    return getChosenAlternativesOnCheckboxChangeWhenMultipleAreAllowed(
      previousChosenAlternatives,
      index,
      checked,
    );
  } else if (checked) {
    return [index];
  } else {
    return previousChosenAlternatives.includes(index)
      ? []
      : previousChosenAlternatives;
  }
};

const getChosenAlternativesOnCheckboxChangeWhenMultipleAreAllowed = (
  previousChosenAlternatives: number[],
  index: number,
  checked: boolean,
) => {
  let chosenAlternatives = previousChosenAlternatives
    ? [...previousChosenAlternatives]
    : [];
  if (checked && !chosenAlternatives.includes(index)) {
    chosenAlternatives.push(index);
  } else if (!checked) {
    chosenAlternatives = chosenAlternatives.filter((item) => item !== index);
  }
  return chosenAlternatives;
};

export const getIsAlternativeCorrect = (
  alternative: MultipleChoiceAlternativeDto,
  isCorrectionMode: boolean,
) => {
  return isCorrectionMode ? alternative.isCorrect : undefined;
};
