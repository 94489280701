import { Button, Card, Text } from '../../atoms';
import styles from './PasswordResetLinkExpired.module.css';
import { useNavigate } from 'react-router-dom';
import { LoginHeader } from '../LoginHeader';

export const PasswordResetLinkExpired = () => {
  const navigate = useNavigate();

  const goBackToLogin = () => {
    navigate('/login');
  };

  return (
    <div className={styles.container}>
      <Card>
        <div className={styles.childContainer}>
          <LoginHeader />

          <Text
            text="Your password reset link has expired. Please go back to the login page and press “Forgot password?” button to receive a new link."
            textKey="passwordReset_link_expired"
            fontSize="bodyLarge"
            fontWeight="regular"
          />
          <div className={styles.button}>
            <Button
              text="Back to login"
              textKey="forgotPassword_to_login"
              onClick={goBackToLogin}
              size="full"
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
