export const PasswordMessagesTx = {
  Missing: 'login_passwordMissing',
  MissingUppercase: 'login_passwordMissingUppercase',
  MissingLowercase: 'login_passwordMissingLowercase',
  MissingSpecialChar: 'login_passwordMissingSpecialChar',
  MissingNumber: 'login_passwordMissingNumber',
  MinimumLength: 'login_password10Char',
};

export const hasUppercase = (input: string): boolean =>
  /^(?=.*[A-Z]).+$/.test(input);
export const hasLowercase = (input: string): boolean =>
  /^(?=.*[a-z]).+$/.test(input);
export const hasSpecialCharacter = (input: string): boolean =>
  /^(?=.*[^a-zA-Z0-9]).+$/.test(input);
export const hasDigit = (input: string): boolean => /^(?=.*\d).+$/.test(input);
export const hasMinimumLength = (input: string, minLength: number): boolean =>
  input.length >= minLength;

export const passwordSchema = (input: string): string | null => {
  if (!input) {
    return PasswordMessagesTx.Missing;
  }
  if (!hasUppercase(input)) {
    return PasswordMessagesTx.MissingUppercase;
  }
  if (!hasLowercase(input)) {
    return PasswordMessagesTx.MissingLowercase;
  }
  if (!hasSpecialCharacter(input)) {
    return PasswordMessagesTx.MissingSpecialChar;
  }
  if (!hasDigit(input)) {
    return PasswordMessagesTx.MissingNumber;
  }
  if (!hasMinimumLength(input, 10)) {
    return PasswordMessagesTx.MinimumLength;
  }
  return null;
};
