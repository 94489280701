export const Clock = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99984 18.3333C14.6022 18.3333 18.3332 14.6023 18.3332 10C18.3332 5.39763 14.6022 1.66667 9.99984 1.66667C5.39746 1.66667 1.6665 5.39763 1.6665 10C1.6665 14.6023 5.39746 18.3333 9.99984 18.3333ZM9.99984 16.6667C13.6818 16.6667 16.6665 13.6819 16.6665 10C16.6665 6.3181 13.6818 3.33334 9.99984 3.33334C6.31794 3.33334 3.33317 6.3181 3.33317 10C3.33317 13.6819 6.31794 16.6667 9.99984 16.6667ZM10.8332 9.16667V5.83334H9.1665V10.8333H13.3332V9.16667H10.8332Z"
        fill="currentColor"
      />
    </svg>
  );
};
