import { useParams } from 'react-router-dom';
import { PasswordReset } from '../../organisms/PasswordReset';
import styles from './RecoverPasswordPage.module.css';
import { useEffect, useState } from 'react';
import {
  resetPassword,
  validateToken,
} from '../../utils/api/legacy-api/auth-api/auth';
import { PasswordChanged } from '../../organisms/PasswordChanged';
import { PasswordResetLinkExpired } from '../../organisms/PasswordResetLinkExpired';
import { AppDispatch } from '../../store';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/UISlice';

enum RecoverPasswordView {
  PasswordReset,
  PasswordChanged,
  PasswordResetLinkExpired,
}

export const RecoverPasswordPage = () => {
  const { userId, resetToken } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [activeView, setActiveView] = useState(
    RecoverPasswordView.PasswordReset,
  );
  useEffect(() => {
    onInitialLoad();
  }, []);

  const onInitialLoad = async () => {
    if (!userId || !resetToken) return;
    try {
      dispatch(setLoading(true));
      await validateToken(userId, resetToken);
    } catch (error) {
      console.error(error);
      setActiveView(RecoverPasswordView.PasswordResetLinkExpired);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onPasswordReset = async (password: string) => {
    if (!userId || !resetToken) return;

    try {
      await resetPassword(userId, resetToken, password);
      setActiveView(RecoverPasswordView.PasswordChanged);
    } catch (error) {
      throw error;
    }
  };

  return (
    <div data-testid="recover-password-page">
      <div className={styles.container}>
        {activeView === RecoverPasswordView.PasswordReset && (
          <PasswordReset onPasswordReset={onPasswordReset} />
        )}
        {activeView === RecoverPasswordView.PasswordChanged && (
          <PasswordChanged />
        )}
        {activeView === RecoverPasswordView.PasswordResetLinkExpired && (
          <PasswordResetLinkExpired />
        )}
      </div>
    </div>
  );
};
