import { useEffect } from 'react';

export const useEscButton = (callback: () => void, enabled = true): void => {
  const callbackOnEsc = (ev: KeyboardEvent) => {
    if (ev.key !== 'Escape') {
      return;
    }
    callback();
  };

  useEffect(() => {
    if (!enabled) {
      return;
    }
    window.addEventListener('keydown', callbackOnEsc);
    return () => {
      window.removeEventListener('keydown', callbackOnEsc);
    };
  });
};
