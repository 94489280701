import { Brand } from '../../assets/brand';
import styles from './CosafeLogo.module.css';

export interface CosafeLogoProps {
  type?: 'light' | 'dark';
}

export const CosafeLogo = (props: CosafeLogoProps) => {
  const { type = 'light' } = props;
  return (
    <img
      className={styles.logo}
      src={type === 'dark' ? Brand.CosafeLogo : Brand.CosafeLogoLight}
      alt={'Cosafe Logo'}
    />
  );
};
