export const Eye = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.15497 5.18053C1.5915 4.95762 2.12608 5.13079 2.34899 5.56732C3.72406 8.26017 6.23864 10 9.05856 10C11.8785 10 14.3931 8.26017 15.7681 5.56732C15.991 5.13079 16.5256 4.95762 16.9621 5.18053C17.3987 5.40344 17.5718 5.93802 17.3489 6.37455C15.724 9.55669 12.6553 11.775 9.05856 11.775C5.46184 11.775 2.39309 9.55669 0.768174 6.37455C0.545265 5.93802 0.718439 5.40344 1.15497 5.18053Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.76378 5.56734C2.38868 2.38524 5.45741 0.166687 9.05416 0.166687C9.37259 0.166687 9.68324 0.185583 9.98527 0.217617C10.4727 0.269312 10.8259 0.70635 10.7742 1.19377C10.7225 1.68118 10.2855 2.03441 9.79806 1.98271C9.55009 1.95641 9.3024 1.94168 9.05416 1.94168C6.23428 1.94168 3.71969 3.68169 2.3446 6.37457C2.12169 6.8111 1.58711 6.98427 1.15058 6.76136C0.714045 6.53846 0.54087 6.00387 0.76378 5.56734Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9137 1.22429C11.0844 0.764812 11.5952 0.530681 12.0547 0.701345C14.3278 1.54567 16.1966 3.31314 17.3484 5.5671C17.5715 6.00356 17.3985 6.5382 16.962 6.76124C16.5255 6.98428 15.9909 6.81127 15.7679 6.37481C14.7947 4.47045 13.2468 3.03761 11.4366 2.36527C10.9772 2.1946 10.743 1.68377 10.9137 1.22429Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.0583 4.71262C8.36334 4.71262 7.79996 5.276 7.79996 5.97096C7.79996 6.66591 8.36334 7.22929 9.0583 7.22929C9.75325 7.22929 10.3166 6.66591 10.3166 5.97096C10.3166 5.276 9.75325 4.71262 9.0583 4.71262ZM6.1333 5.97096C6.1333 4.35553 7.44287 3.04596 9.0583 3.04596C10.6737 3.04596 11.9833 4.35553 11.9833 5.97096C11.9833 7.58638 10.6737 8.89596 9.0583 8.89596C7.44287 8.89596 6.1333 7.58638 6.1333 5.97096Z"
        fill="currentColor"
      />
    </svg>
  );
};
