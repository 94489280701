import { Button, Card, Text } from '../../atoms';
import { CourseStatus, CourseTag } from '../../utils/interfaces';
import { Checkbox, CheckboxProps } from '../Checkbox';
import styles from './CourseFilterDisplay.module.css';
import { Icons } from '../../assets/icons';
import { CourseCount } from '../../utils/hooks';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { TextProps } from '../../atoms/Text';

export interface CourseFilter {
  statuses: CourseStatus[];
  tags: CourseTag[];
  isPremium: boolean;
}
type CourseFilterVariant = 'default' | 'mobile';
interface CourseFilterDisplayProps {
  courseCount?: CourseCount;
  filter: CourseFilter;
  onApply: (filter: CourseFilter) => void;
  isLoading?: boolean;
  variant?: CourseFilterVariant;
  dismiss?: () => void;
}

const commonSectionTextProps: Partial<TextProps> = {
  fontSize: 'body',
  fontWeight: 'medium',
};

export const CourseFilterDisplay = (props: CourseFilterDisplayProps) => {
  const {
    filter,
    onApply,
    isLoading,
    courseCount,
    variant = 'default',
    dismiss,
  } = props;
  const isMobile = variant === 'mobile';
  const [statuses, setStatuses] = useState(filter.statuses);
  const [tags, setTags] = useState(filter.tags);
  const [isPremium, setIsPremium] = useState(filter.isPremium);
  // handling status for mobile varient, will be by storing the status in a staged state
  const [stagedStatuses, setStagedStatuses] = useState(filter.statuses);
  const [stagedTags, setStagedTags] = useState(filter.tags);
  const [stagedIsPremium, setStagedIsPremium] = useState(filter.isPremium);

  const resetAllFilters = () => {
    setStatuses([]);
    setTags([]);
    setIsPremium(false);
    setStagedStatuses([]);
    setStagedTags([]);
    setStagedIsPremium(false);
  };

  // onClear available only for mobile variant
  const onClear = () => {
    if (isMobile) {
      resetAllFilters();
      onApply({
        statuses: [],
        tags: [],
        isPremium: false,
      });
      dismiss?.();
    }
  };

  // onApply available only for mobile variant
  const onApplyMobile = () => {
    if (isMobile) {
      setStatuses(stagedStatuses);
      setTags(stagedTags);
      setIsPremium(stagedIsPremium);
      onApply({
        statuses: stagedStatuses,
        tags: stagedTags,
        isPremium: stagedIsPremium,
      });
      dismiss?.();
    }
  };

  const applyChange = (callbacks: Record<CourseFilterVariant, () => void>) => {
    callbacks[variant]();
  };

  const extractValue = (_variant: CourseFilterVariant): CourseFilter => {
    switch (_variant) {
      case 'default':
        return {
          statuses,
          tags,
          isPremium,
        };
      case 'mobile':
        return {
          statuses: stagedStatuses,
          tags: stagedTags,
          isPremium: stagedIsPremium,
        };
    }
  };

  const extractedStatuses = extractValue(variant).statuses;
  const extractedTags = extractValue(variant).tags;
  const extractedIsPremium = extractValue(variant).isPremium;

  const { t } = useTranslation();

  const commonCheckboxProps: Partial<CheckboxProps> = {
    size: 'small',
    checkboxPosition: 'right',
    isLoading,
  };

  const getCourseStatusIcon = (courseStatus: CourseStatus) => {
    switch (courseStatus) {
      case CourseStatus.Ongoing:
        return <Icons.RadioChecked className={styles.icon} />;
      case CourseStatus.Todo:
        return <Icons.CirclePartiallyCrossed className={styles.icon} />;
      case CourseStatus.Completed:
        return <Icons.CheckmarkRound className={styles.icon} />;
    }
  };

  const onStatusChange = (status: CourseStatus) => {
    const newStatuses = extractedStatuses.includes(status)
      ? extractedStatuses.filter((s) => s !== status)
      : [...extractedStatuses, status];
    applyChange({
      default: () => {
        setStatuses(newStatuses);
        onApply({
          statuses: newStatuses,
          tags,
          isPremium,
        });
      },
      mobile: () => {
        setStagedStatuses(newStatuses);
      },
    });
  };

  const onTagChange = (tag: CourseTag) => {
    const newTags = extractedTags.includes(tag)
      ? extractedTags.filter((t) => t !== tag)
      : [...extractedTags, tag];

    applyChange({
      default: () => {
        setTags(newTags);
        onApply({
          statuses,
          tags: newTags,
          isPremium,
        });
      },
      mobile: () => {
        setStagedTags(newTags);
      },
    });
  };

  const onPremiumChange = () => {
    const newIsPremium = !extractedIsPremium;
    applyChange({
      default: () => {
        setIsPremium(newIsPremium);
        onApply({
          statuses,
          tags,
          isPremium: newIsPremium,
        });
      },
      mobile: () => {
        setStagedIsPremium(newIsPremium);
      },
    });
  };

  return (
    <Card border={isMobile ? 'none' : 'dark'}>
      <div className={styles.headerContainer}>
        <Text
          text="Filters"
          textKey="course_filter_header"
          fontSize="h3"
          fontWeight="bold"
        />
      </div>
      <div className={styles.paddingContainer}>
        <Text
          {...commonSectionTextProps}
          text="Status"
          textKey="course_filter_status_header"
        />
      </div>
      {Object.values(CourseStatus).map((status) => (
        <div key={status} className={styles.paddingContainer}>
          <Checkbox
            {...commonCheckboxProps}
            text={t(`course_filter_status_${status}`, {
              value:
                !isLoading && courseCount?.status?.[status] !== undefined
                  ? `(${courseCount?.status?.[status]})`
                  : '',
            })}
            checked={extractedStatuses.includes(status)}
            onChange={() => onStatusChange(status)}
            icon={getCourseStatusIcon(status)}
          />
        </div>
      ))}
      <div className={styles.divider} />
      <div className={styles.paddingContainer}>
        <Text
          {...commonSectionTextProps}
          text="Course type"
          textKey="course_filter_tag_header"
        />
      </div>
      {Object.values(CourseTag).map((tag) => (
        <div key={tag} className={styles.paddingContainer}>
          <Checkbox
            {...commonCheckboxProps}
            text={t(`course_filter_tag_${tag}`, {
              value:
                !isLoading && courseCount?.tag?.[tag] !== undefined
                  ? `(${courseCount?.tag?.[tag]})`
                  : '',
            })}
            checked={extractedTags?.includes(tag)}
            onChange={() => onTagChange(tag)}
          />
        </div>
      ))}

      <div className={styles.divider} />
      <div className={styles.paddingContainer}>
        <Checkbox
          {...commonCheckboxProps}
          text={t('course_filter_type_Premium', {
            value:
              !isLoading && courseCount?.premium !== undefined
                ? `(${courseCount?.premium})`
                : '',
          })}
          checked={extractedIsPremium}
          onChange={() => onPremiumChange()}
          icon={
            <Icons.ShootingStar
              className={`${styles.icon} ${styles.attention}`}
            />
          }
        />
      </div>
      {isMobile && (
        <div className={styles.buttonsContainer}>
          <Button
            textKey="courses_filters_apply"
            size="full"
            type="secondary"
            onClick={onApplyMobile}
          />
          <Button
            textKey="courses_filters_clear"
            size="full"
            type="bare"
            onClick={onClear}
          />
        </div>
      )}
    </Card>
  );
};
