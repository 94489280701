import { PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getItem } from './utils/storage';
import { AppDispatch } from './store';
import { selectUser, setUser } from './redux/Login/LoginSlice';
import { processInternalReroutablePaths } from './utils/routesUtils';

export const RequireAuth = ({
  children,
  currentPath,
}: PropsWithChildren<{ currentPath: string }>) => {
  const user = getItem('user');
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();

  const userState = useSelector(selectUser);

  useEffect(() => {
    if (user && !userState) {
      dispatch(setUser(user));
    }
    if (!user) {
      const redirectUrl = processInternalReroutablePaths(currentPath, location);
      navigate(
        '/login',
        redirectUrl
          ? { state: { from: { pathname: redirectUrl } } }
          : undefined,
      );
    }
  }, [user, userState, dispatch, navigate, currentPath, location]);

  if (!user) {
    return null; // or a loading spinner if desired
  }
  return children;
};
