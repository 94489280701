import { useEffect, useState } from 'react';
import { Icons } from '../../assets/icons';
import { IconButton } from '../../atoms/IconButton';
import { MultipleChoiceQuestion } from '../../molecules';
import {
  ModuleFeedback,
  ModuleFeedbackType,
} from '../../molecules/ModuleFeedback';
import { QuestionDto } from '../../utils/interfaces';
import {
  getCorrectAnswers,
  getInitialQuizState,
  getNumberOfCorrectAnswers,
  isMissingAnswers,
} from './QuizComponent.helper';
import styles from './QuizComponent.module.css';

export interface QuizComponentProps {
  questions: QuestionDto[];
  isReviewMode: boolean;
  isLastModule: boolean;
  onContinue: () => void;
  doSaveProgress: () => void;
  shuffleQuestionsAlternatives: () => void;
}

export const QuizComponent = (props: QuizComponentProps) => {
  const {
    questions,
    isReviewMode,
    isLastModule,
    onContinue,
    doSaveProgress,
    shuffleQuestionsAlternatives,
  } = props;

  const initialQuizState = getInitialQuizState(questions);
  const [quizState, setQuizState] = useState<number[][]>(initialQuizState);
  const [showModuleFeedback, setShowModuleFeedback] =
    useState<ModuleFeedbackType>('none');
  const [numberOfCorrectAnswers, setNumberOfCorrectAnswers] =
    useState<number>(0);
  const [isValidatingAnswers, setIsValidatingAnswers] = useState(false);
  const [isJustCompletedModule, setIsJustCompletedModule] = useState(false);

  useEffect(() => {
    if (isReviewMode) {
      setQuizState(getCorrectAnswers(questions));
    } else {
      setQuizState(getInitialQuizState(questions));
    }
    setShowModuleFeedback(
      isReviewMode
        ? isLastModule
          ? 'courseCompleted'
          : 'moduleCompleted'
        : 'none',
    );
    setNumberOfCorrectAnswers(isReviewMode ? questions.length : 0);
  }, [questions, isReviewMode, isLastModule]);

  const updateQuizState = (index: number, chosenAnswers: number[]) => {
    setQuizState((prevStates) => {
      const newState = [...prevStates];
      newState[index] = chosenAnswers;
      return newState;
    });
  };

  const renderQuestions = () => {
    return questions.map((question: QuestionDto, index: number) => (
      <div key={index} className={styles.questionContainer}>
        <MultipleChoiceQuestion
          questionNumber={index + 1}
          question={question}
          isCorrectionMode={
            showModuleFeedback !== 'none' &&
            (isJustCompletedModule || !isReviewMode)
          }
          isDisabled={isReviewMode}
          chosenAnswers={quizState[index] ?? []}
          onAnswerChange={(chosenAnswers) =>
            updateQuizState(index, chosenAnswers)
          }
        />
      </div>
    ));
  };

  const validateAnswers = () => {
    if (isValidatingAnswers) return;

    setIsValidatingAnswers(true);
    // TODO: Remove timeout when async validation is implemented
    // Currently I added it so user can see loading button
    setTimeout(() => {
      const correctAnswersCount = getNumberOfCorrectAnswers(
        questions,
        quizState,
      );
      setNumberOfCorrectAnswers(correctAnswersCount);
      if (questions.length === correctAnswersCount) {
        setIsJustCompletedModule(true);
        doSaveProgress();
        setShowModuleFeedback(
          isLastModule ? 'courseCompleted' : 'moduleCompleted',
        );
      } else {
        setShowModuleFeedback('moduleNotCompleted');
      }
      setIsValidatingAnswers(false);
    }, 500);
  };

  const onModuleFeedBackContinue = async () => {
    if (showModuleFeedback === 'moduleNotCompleted') {
      setShowModuleFeedback('none');
      setQuizState(initialQuizState);
      shuffleQuestionsAlternatives();
    } else {
      onContinue();
    }
  };

  const getLoaderIcon = () => {
    if (!isValidatingAnswers) return undefined;

    return <Icons.Loader className={styles.loaderIcon} />;
  };

  return (
    <div
      className={`${styles.container} ${styles.questionContainerWithSubmitBtn}`}
    >
      {renderQuestions()}
      <div className={styles.stickyBottomContainer}>
        {showModuleFeedback !== 'none' ? (
          <ModuleFeedback
            type={showModuleFeedback}
            value={numberOfCorrectAnswers!}
            maxValue={questions.length}
            onButtonClick={onModuleFeedBackContinue}
          />
        ) : (
          <div className={styles.iconButtonWrapper}>
            <IconButton
              text={
                isValidatingAnswers
                  ? 'Processing your answers...'
                  : 'Submit your answers'
              }
              textKey={
                isValidatingAnswers
                  ? 'module_button_processingAnswers'
                  : 'module_button_submitAnswers'
              }
              onClick={validateAnswers}
              disabled={isMissingAnswers(quizState)}
              size="full"
              justifyContent="center"
              leftIcon={getLoaderIcon()}
            />
          </div>
        )}
      </div>
    </div>
  );
};
