import { useNavigate } from 'react-router-dom';
import styles from './NotFound.module.css';
import { Images } from '../../assets/images';
import { Button, Text } from '../../atoms';
import { Icons } from '../../assets/icons';
import { UserCourseResult } from '../../utils/interfaces';

export const NotFound = ({
  highlightedCourse,
}: {
  highlightedCourse: UserCourseResult | null | undefined;
}) => {
  const navigate = useNavigate();

  const getCustomBTNContent = (course: UserCourseResult) => {
    return (
      <div
        className={styles.customButtonContainer}
        onClick={() => redirectToActiveCourse(course)}
      >
        <div className={styles.customButtonContent}>
          <div>
            <Text
              text="GO TO COURSE"
              textKey="go_to_course"
              fontSize="label"
              fontWeight="bold"
              variant="help"
            />
          </div>
          <div>
            <Text
              text={course.title}
              fontSize="body"
              fontWeight="bold"
              variant="primary"
              truncate="oneLine"
            />
          </div>
        </div>
        <div className={styles.customButtonIconContainer}>
          {<Icons.RightArrow />}
        </div>
      </div>
    );
  };

  const redirectToActiveCourse = (course: UserCourseResult) => {
    navigate(`/courses/${course._id}`);
  };

  const onBackToCourses = () => {
    navigate('/');
  };

  return (
    <div className={styles.centeredContent}>
      <img
        src={Images.NotFound}
        alt="Not Found Page"
        className={styles.notFoundIllustration}
      />
      <div className={styles.containerContent}>
        <Text
          text="We couldnt find this page or it does not exist"
          textKey="not_found_page_title"
          fontSize="h2"
          fontWeight="bold"
          textAlign="center"
        />
        <Text
          text="We don’t have a course for this incident yet. But we can take you back to the homepage or your currently active course."
          textKey="not_found_page_subtitle"
          fontSize="bodyLarge"
          textAlign="center"
        />
        <div className={styles.buttonsContainer}>
          <Button
            type="secondary"
            text="Go back to homepage"
            textKey="not_found_page_back_to_homepage"
            size={`${highlightedCourse ? 'full-auto' : 'full'}`}
            onClick={onBackToCourses}
          />
          {highlightedCourse && (
            <Button
              customContent={getCustomBTNContent(highlightedCourse)}
              type="primary"
              size="full-auto"
            />
          )}
        </div>
      </div>
    </div>
  );
};
