import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'debounce';
import styles from './CoursesPage.module.css';
import { Courses } from '../../organisms';
import { useFetchCourses } from '../../utils/hooks';
import { selectUser } from '../../redux/Login/LoginSlice';
import { LanguageSelector } from '../../molecules';
import { logoutUser } from '../../redux/Login/LoginSlice/actionCreators';
import { AppDispatch } from '../../store';
import { logoutRequest } from '../../utils/api/legacy-api/auth-api/auth';
import {
  selectIsMenuOpen,
  setLoading,
  toggleMenuOpen,
} from '../../redux/UISlice';
import { UserCard } from '../../molecules/UserCard';
import { UserCourseResult } from '../../utils/interfaces';
import { extractSearchParams } from './CoursesPage.helper';
import { SEARCH_DEBOUNCE_DURATION_MS } from '../../config/consts';
import { HamburgerMenuButton } from '../../molecules/HamburgerMenuButton';
import { CoursesPageModal } from '../../organisms/CoursesPageModal';

export const CoursesPage: React.FC = () => {
  const isMenuOpen = useSelector(selectIsMenuOpen);

  const [searchParams] = useSearchParams();
  const user = useSelector(selectUser);

  const { filter, page, searchText } = extractSearchParams(searchParams);
  const {
    courses,
    highlightedCourse,
    courseCount,
    paginationMeta,
    error,
    setPage,
    setSearch,
    setFilter,
  } = useFetchCourses(filter, page, searchText);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const doLogout = async () => {
    dispatch(setLoading(true));
    await logoutRequest();
    dispatch(logoutUser());
    dispatch(setLoading(false));
    window.location.reload();
  };

  const debouncedUpdateSearch = debounce((nextSearchText: string) => {
    setSearch(nextSearchText);
  }, SEARCH_DEBOUNCE_DURATION_MS);

  const onCourseCardClick = (course: UserCourseResult) => {
    const currentUrlSearch = location.search;
    navigate(`/courses/${course._id}`, {
      state: { from: `/${currentUrlSearch}` },
    });
  };

  const onChangePageClick = (nextPage: number) => {
    setPage(nextPage);
  };

  const onChangeSearch = (nextSearchText: string) => {
    debouncedUpdateSearch(nextSearchText);
  };

  if (error) {
    if (error?.response?.status === 401) {
      dispatch(logoutUser());
      dispatch(setLoading(false));
      window.location.reload();
      return;
    }
  }

  return (
    <div data-testid="courses-page" className={styles.container}>
      <div className={styles.userAndLanguageContainer}>
        <div className={styles.languageSelector}>
          <LanguageSelector hasBorder={false} />
        </div>
        <div className={styles.userCard}>
          <UserCard
            hasBorder={false}
            doLogout={doLogout}
            name={user?.displayName ?? 'Learner'}
            photoFileName={user?.photoFileName}
          />
        </div>
        <HamburgerMenuButton
          isOpen={isMenuOpen}
          onClick={() => {
            dispatch(toggleMenuOpen());
          }}
        />
      </div>
      <CoursesPageModal
        isMenuOpen={isMenuOpen}
        logoutRequest={logoutRequest}
        logoutUser={() => dispatch(logoutUser())}
      />
      <Courses
        name={user?.displayName ?? 'Learner'}
        courses={courses}
        highlightedCourse={highlightedCourse}
        paginationData={paginationMeta}
        filter={filter}
        courseCount={courseCount}
        onApplyFilter={setFilter}
        onCourseCardClick={onCourseCardClick}
        onChangePageClick={onChangePageClick}
        onChangeSearch={onChangeSearch}
      />
    </div>
  );
};
