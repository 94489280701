export const Lock = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.41667 6.58744C2.72631 6.58744 2.16667 7.14709 2.16667 7.83744V14.4166C2.16667 15.107 2.72631 15.6666 3.41667 15.6666H12.1083C12.7987 15.6666 13.3583 15.107 13.3583 14.4166V7.83744C13.3583 7.14709 12.7987 6.58744 12.1083 6.58744H3.41667ZM0.5 7.83744C0.5 6.22661 1.80584 4.92078 3.41667 4.92078H12.1083C13.7192 4.92078 15.025 6.22661 15.025 7.83744V14.4166C15.025 16.0275 13.7192 17.3333 12.1083 17.3333H3.41667C1.80584 17.3333 0.5 16.0275 0.5 14.4166V7.83744Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.76675 2.33329C6.46031 2.33329 5.40007 3.39352 5.40007 4.69993V5.75411C5.40007 6.21435 5.02697 6.58744 4.56673 6.58744C4.10649 6.58744 3.7334 6.21435 3.7334 5.75411V4.69993C3.7334 2.47301 5.53987 0.666626 7.76675 0.666626C9.99366 0.666626 11.8001 2.47303 11.8001 4.69993V5.75411C11.8001 6.21435 11.427 6.58744 10.9667 6.58744C10.5065 6.58744 10.1334 6.21435 10.1334 5.75411V4.69993C10.1334 3.3935 9.07318 2.33329 7.76675 2.33329Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.76253 8.98743C8.22277 8.98743 8.59587 9.36052 8.59587 9.82076V12.2333C8.59587 12.6935 8.22277 13.0666 7.76253 13.0666C7.3023 13.0666 6.9292 12.6935 6.9292 12.2333V9.82076C6.9292 9.36052 7.3023 8.98743 7.76253 8.98743Z"
        fill="currentColor"
      />
    </svg>
  );
};
