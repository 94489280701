import styles from './FloatingButton.module.css';
import { ReactElement } from 'react';

export type FloatingButtonProps = {
  content?: ReactElement;
  className?: string;
  onClick: () => void;
};

export const FloatingButton = (props: FloatingButtonProps) => {
  const { content, className, onClick } = props;
  return (
    <button
      className={`${styles.container} ${className ?? ''}`}
      onClick={onClick}
    >
      {content}
    </button>
  );
};
