export const saveItem = (key: string, item: Object) => {
  const itemString = JSON.stringify(item);
  localStorage.setItem(key, itemString);
};

export const getItem = (key: string) => {
  const item: string | null = localStorage.getItem(key);

  let itemObject;
  if (item) {
    try {
      itemObject = JSON.parse(item);
    } catch {
      itemObject = null;
      console.error('not valid JSON');
    }
  }

  return itemObject;
};

export const deleteItem = (key: string) => {
  localStorage.removeItem(key);
};
