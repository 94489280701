import { useTranslation } from 'react-i18next';
import { getButtonClassName } from './Button.helper';
import { ReactElement } from 'react';
import styles from './Button.module.css';

export type ButtonType = 'primary' | 'secondary' | 'bare' | 'bareAttention';

export type ButtonSize = 'md' | 'full' | 'full-auto';

export interface ButtonProps {
  type?: ButtonType;
  text?: string;
  textKey?: string;
  customContent?: ReactElement;
  size?: ButtonSize;
  disabled?: boolean;
  icon?: ReactElement;
  onClick?: () => void;
}

export const Button = (props: ButtonProps) => {
  const {
    type = 'primary',
    text = '',
    textKey = '',
    size = 'md',
    disabled = false,
    customContent = undefined,
    icon = undefined,
    onClick,
  } = props;
  const { t } = useTranslation();
  if (customContent) {
    return (
      <button
        className={getButtonClassName(type, size)}
        onClick={onClick}
        disabled={disabled}
      >
        {customContent}
      </button>
    );
  }
  return (
    <button
      className={getButtonClassName(type, size)}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <div className={styles.iconContainer}>{icon}</div>}
      {icon && (
        <div className={styles.textContainer}>
          {textKey ? t(textKey, text) : text || text}
        </div>
      )}
      {!icon && (textKey ? t(textKey, text) : text || text)}
    </button>
  );
};
