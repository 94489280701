import { Button, Card, InputField, Text } from '../../atoms';
import styles from './PasswordReset.module.css';
import { useNavigate } from 'react-router-dom';
import { LoginHeader } from '../LoginHeader';
import { useMemo, useState } from 'react';
import { passwordSchema } from './PasswordReset.helpers';
import { Icons } from '../../assets/icons';
import { Modals } from '../../providers';

interface PasswordError {
  error: string;
  isMainPasswordError: boolean;
}

interface PasswordResetProps {
  onPasswordReset: (password: string) => void;
}

export const PasswordReset = ({ onPasswordReset }: PasswordResetProps) => {
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [loading, setLoading] = useState(false);

  const error = useMemo<PasswordError | undefined>(() => {
    if (!password?.length) return;

    const passwordError = passwordSchema(password);
    if (passwordError) {
      return {
        error: passwordError,
        isMainPasswordError: true,
      };
    }

    if (passwordConfirmation?.length && password !== passwordConfirmation) {
      return {
        error: 'login_passwordsDoNotMatch',
        isMainPasswordError: false,
      };
    }

    return;
  }, [password, passwordConfirmation]);
  const isValid =
    password?.length && !error && password === passwordConfirmation;

  const goBackToLogin = () => {
    navigate('/login');
  };

  const onPasswordChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
  };

  const onPasswordConfirmationChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    setPasswordConfirmation(value);
  };

  const passwordResetAction = async () => {
    if (!isValid) return;
    try {
      setLoading(true);
      await onPasswordReset(password);
    } catch (error) {
      Modals.ShowSnack({
        type: 'error',
        text: 'Something went wrong. Please try again later.',
        textKey: 'login_error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <Card>
        <div className={styles.childContainer}>
          <LoginHeader />

          <Text
            text="Enter your new password and confirm it in the next field"
            textKey="passwordReset_info"
            fontSize="bodyLarge"
            fontWeight="regular"
          />

          <InputField
            label="Type new password"
            labelKey="passwordReset_type_password"
            helperText="New password"
            helperTextKey="passwordReset_new_password"
            type="password"
            value={password}
            onChange={onPasswordChange}
            errorTextKey={error?.isMainPasswordError ? error.error : undefined}
          />

          <InputField
            label="Confirm password"
            labelKey="passwordReset_confirm_password"
            helperText="Confirm password"
            helperTextKey="passwordReset_confirm_password"
            type="password"
            value={passwordConfirmation}
            onChange={onPasswordConfirmationChange}
            errorTextKey={
              error && !error?.isMainPasswordError ? error.error : undefined
            }
          />

          <div className={`${styles.button} ${styles.firstButton}`}>
            <Button
              text="Confirm new password"
              textKey="passwordReset_confirm_new_password"
              onClick={passwordResetAction}
              size="full"
              icon={
                loading ? (
                  <Icons.Loader className={styles.loaderIcon} />
                ) : undefined
              }
              disabled={!isValid || loading}
            />
          </div>
          <div className={styles.button}>
            <Button
              text="Back to login"
              textKey="forgotPassword_to_login"
              onClick={goBackToLogin}
              size="full"
              type="secondary"
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
