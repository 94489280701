import { useEffect, useState } from 'react';
import { AnimationType } from '../../utils/types/uiTypes';
import styles from './BottomSheet.module.css';
import { useEscButton } from '../../utils/hooks/useEscButton';
import { usePreventScroll } from '../../utils/hooks/usePreventScroll';
import { Icons } from '../../assets/icons';

export interface BottomSheetProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
}
const ANIMATION_DURATION = 200;
export const BottomSheet = (props: BottomSheetProps) => {
  const { isOpen: outerIsOpen, setIsOpen, children } = props;
  const [internalIsOpen, setIsInternalOpen] = useState<AnimationType>('CLOSED');
  const isVisible = internalIsOpen !== 'CLOSED';

  useEffect(() => {
    if (outerIsOpen) {
      setIsInternalOpen('ANIMATE_OPENING');
      setTimeout(() => {
        setIsInternalOpen('OPENED');
      }, ANIMATION_DURATION);
    } else {
      if (internalIsOpen === 'CLOSED') return;
      setIsInternalOpen('ANIMATE_CLOSING');
      setTimeout(() => {
        setIsInternalOpen('CLOSED');
      }, ANIMATION_DURATION);
    }
  }, [outerIsOpen]);

  const dismiss = () => setIsOpen(false);

  useEscButton(dismiss, isVisible);
  usePreventScroll(isVisible, styles.container);

  const stateClassMap: Record<AnimationType, string> = {
    ANIMATE_OPENING: styles.entering,
    OPENED: styles.entered,
    ANIMATE_CLOSING: styles.exiting,
    CLOSED: '',
  };

  const containerClassName = `${styles.container} ${stateClassMap[internalIsOpen] || styles.exiting}`;

  if (!isVisible) return null;

  return (
    <>
      <div
        className={`${styles.backdrop} ${
          internalIsOpen === 'ANIMATE_CLOSING' ? styles.fadeOut : styles.fadeIn
        }`}
        onClick={dismiss}
      ></div>
      <div className={containerClassName}>
        <div className={styles.closeButton} onClick={dismiss}>
          <Icons.Cross className={styles.cross} />
        </div>
        {children}
      </div>
    </>
  );
};
