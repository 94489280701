import styles from './Badge.module.css';
import { Icons } from '../../assets/icons';
import { ReactElement } from 'react';
import { Text, TextVariant } from '../Text';
import { CourseStatus, CourseTag, CourseType } from '../../utils/interfaces';

interface BadgeProps {
  type: CourseStatus | CourseTag | CourseType;
}

interface BadgeDetails {
  text?: string;
  textKey?: string;
  textVariant?: TextVariant;
  icon?: ReactElement;
  className: string;
}

const getBadgeDetails = (
  type: CourseStatus | CourseTag | CourseType,
): BadgeDetails => {
  switch (type) {
    case CourseStatus.Ongoing:
      return {
        text: 'In progress',
        textKey: `course_badge_status_${type}`,
        icon: (
          <Icons.RadioChecked className={`${styles.icon} ${styles.dark}`} />
        ),
        className: 'inProgress',
      };
    case CourseStatus.Completed:
      return {
        text: 'Completed',
        textKey: `course_badge_status_${type}`,
        textVariant: 'complementary',
        icon: <Icons.CheckmarkRound className={styles.icon} />,
        className: 'completed',
      };
    case CourseStatus.Todo:
      return {
        text: 'Not started',
        textKey: `course_badge_status_${type}`,
        icon: (
          <Icons.CirclePartiallyCrossed
            className={`${styles.icon} ${styles.dark}`}
          />
        ),
        className: 'notStarted',
      };
    case CourseTag.User:
    case CourseTag.Admin:
      return {
        textKey: `course_badge_tag_${type}`,
        className: 'tag',
      };
    case CourseType.Premium:
      return {
        icon: (
          <Icons.ShootingStar
            className={`${styles.icon} ${styles.attention}`}
          />
        ),
        className: 'premium',
      };
    default:
      return {
        className: type,
      };
  }
};

export const Badge = (props: BadgeProps) => {
  const { type } = props;
  const {
    text,
    textKey,
    icon,
    className,
    textVariant = 'none',
  } = getBadgeDetails(type);

  return (
    <div
      className={`${styles.badge} ${styles[className]} ${text || textKey ? styles.withText : ''}`}
    >
      {icon}
      {text || textKey ? (
        <div className={`${styles.text} ${icon ? styles.withIcon : ''}`}>
          <Text
            text={text || ''}
            textKey={textKey}
            fontSize="titleSmall"
            fontWeight="regular"
            variant={textVariant}
          />
        </div>
      ) : null}
    </div>
  );
};
