import styles from './ModuleFeedback.module.css';
import { Button, Text } from '../../atoms';
import { CircularProgressBar } from '../CircularProgressBar';
import { getModuleFeedbackText } from './ModuleFeedback.helper';
import { useState } from 'react';
import { Gifs } from '../../assets/gifs';

export type ModuleFeedbackType =
  | 'none'
  | 'moduleNotCompleted'
  | 'moduleCompleted'
  | 'courseCompleted';

interface ModuleFeedbackProps {
  type: ModuleFeedbackType;
  value: number;
  maxValue: number;
  onButtonClick: () => void;
}

export const ModuleFeedback = (props: ModuleFeedbackProps) => {
  const { type, value, maxValue, onButtonClick } = props;
  const {
    title,
    titleKey,
    description,
    descriptionKey,
    buttonText,
    buttonTextKey,
  } = getModuleFeedbackText(type);
  const progressBarStyle = {
    radius: 24,
    strokeWidth: 4,
  };
  const [isClosing, setIsClosing] = useState(false);

  const handleClick = () => {
    setIsClosing(true);
  };

  const isSuccess = type === 'moduleCompleted' || type === 'courseCompleted';

  return (
    <div
      className={`${styles.wrapper}  ${isClosing ? styles.close : ''}`}
      onAnimationEnd={() => {
        if (isClosing) onButtonClick();
      }}
    >
      {isSuccess && (
        <img src={Gifs.Confetti} alt="confetti" className={styles.confetti} />
      )}
      <div className={`${styles.container} ${styles[type]}`}>
        <div className={styles.contentContainer}>
          <div className={styles.progressContainer}>
            <CircularProgressBar
              value={value}
              maxValue={maxValue}
              radius={progressBarStyle.radius}
              strokeWidth={progressBarStyle.strokeWidth}
            />
          </div>
          <div className={styles.textContainer}>
            <Text
              text={title}
              textKey={titleKey}
              fontSize="label"
              fontWeight="bold"
            />
            <Text
              text={description}
              textKey={descriptionKey}
              fontSize="body"
              fontWeight="medium"
            />
          </div>
          <div className={styles.buttonContainer}>
            <Button
              type="bare"
              size="full"
              text={buttonText}
              textKey={buttonTextKey}
              onClick={handleClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
