import { ReactElement } from 'react';
import styles from './MenuItem.module.css';
export interface MenuItemProps {
  children: ReactElement;
  disabled?: boolean;
  onClick?: () => void;
}

export const MenuItem = (props: MenuItemProps) => {
  const { children, disabled, onClick } = props;
  return (
    <button className={styles.button} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};
