import { useState } from 'react';
import { ForgotPassword } from '../../organisms/ForgotPassword';
import { sendRecoveryEmail } from '../../utils/api/legacy-api/auth-api/auth';
import { useFeatureFlags } from '../../utils/feature-flags/FeatureFlagProvider';
import styles from './ForgotPasswordPage.module.css';
import { PasswordRecoverySent } from '../../organisms/PasswordRecoverySent';

export const ForgotPasswordPage = () => {
  const { enablePasswordResetWithoutRedirect } = useFeatureFlags();
  const [emailSent, setEmailSent] = useState<string | undefined>();

  if (!enablePasswordResetWithoutRedirect) {
    const baseUrl =
      process.env.REACT_APP_WEBAPP_URL || 'https://webapp.awsdev.cosafe.com';
    window.location.href = `${baseUrl}/forgetPassword`;
    return;
  }

  const sendInstructions = async (email: string) => {
    try {
      await sendRecoveryEmail(email);
      setEmailSent(email);
    } catch (error) {
      throw error;
    }
  };

  return (
    <div data-testid="forgot-password-page">
      <div className={styles.container}>
        {!emailSent && <ForgotPassword onSendInstructions={sendInstructions} />}
        {!!emailSent && (
          <PasswordRecoverySent
            email={emailSent}
            onResendInstructions={sendInstructions}
          />
        )}
      </div>
    </div>
  );
};
