import { UserCourseResult } from '../../interfaces';
import { ICoursePaginationRequest } from '../../interfaces/academy-api/icoursePaginationRequest';
import { CoursePaginationResponse } from '../../interfaces/academy-api/icoursePaginationResponse';
import { academyApi } from './academyApi';

export const getCourses = async (
  queryParams: ICoursePaginationRequest,
): Promise<CoursePaginationResponse> => {
  try {
    const response = await academyApi.request<CoursePaginationResponse>({
      method: 'GET',
      resourcePath: '/me',
      queryParams,
    });
    return response;
  } catch (error) {
    console.error('Error fetching courses', error);
    throw error;
  }
};

export const getCourseById = async (
  courseId: string,
): Promise<UserCourseResult> => {
  try {
    const response = await academyApi.request<UserCourseResult>({
      method: 'GET',
      resourcePath: `/me/courses`,
      resourceId: courseId.toString(),
    });
    return response;
  } catch (error) {
    console.error(`Error fetching course with ID ${courseId}`, error);
    throw error;
  }
};

export const postModuleCompleted = async (
  courseId: string,
  moduleId: string,
): Promise<void> => {
  try {
    const response = await academyApi.request<void>({
      method: 'POST',
      resourcePath: '/me/completed',
      data: {
        courseId,
        moduleId,
      },
    });
    return response;
  } catch (error) {
    console.error('Error updating module status', error);
    throw error;
  }
};
