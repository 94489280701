import { useEffect, useState } from 'react';
import { Icons } from '../../assets/icons';
import { FloatingButton } from '../../atoms/FloatingButton';
import styles from './HamburgerMenuButton.module.css';
import { AnimationType } from '../../utils/types/uiTypes';

export interface HamburgerMenuButtonProps {
  isOpen: boolean;
  onClick: () => void;
}

const ANIMATION_DURATION = 300;

export const HamburgerMenuButton = (props: HamburgerMenuButtonProps) => {
  const { isOpen: outerIsOpen, onClick } = props;

  const [internalIsOpen, setIsInternalOpen] = useState<AnimationType>('CLOSED');

  const animateHamBurgerOut = internalIsOpen === 'ANIMATE_OPENING';
  const animateCrossOut = internalIsOpen === 'ANIMATE_CLOSING';
  const animateHamburgerIn = internalIsOpen === 'CLOSED';
  const isOpen =
    internalIsOpen === 'OPENED' || internalIsOpen === 'ANIMATE_CLOSING';

  useEffect(() => {
    if (outerIsOpen) {
      setIsInternalOpen('ANIMATE_OPENING');
      setTimeout(() => {
        setIsInternalOpen('OPENED');
      }, ANIMATION_DURATION);
    } else {
      if (internalIsOpen === 'CLOSED') return;
      setIsInternalOpen('ANIMATE_CLOSING');
      setTimeout(() => {
        setIsInternalOpen('CLOSED');
      }, ANIMATION_DURATION);
    }
  }, [outerIsOpen]);

  return (
    <FloatingButton
      className={`${styles.hamburgerMenuContainer} ${isOpen ? styles.open : ''}    `}
      onClick={onClick}
      content={
        isOpen ? (
          <Icons.Cross
            className={`${styles.crossIcon} ${animateCrossOut && styles.animateCrossOut}`}
          />
        ) : (
          <Icons.HamburgerMenu
            className={`${styles.hamburgerIcon} ${animateHamBurgerOut && styles.animateHamburgerOut} ${animateHamburgerIn && styles.animateHamburgerIn}`}
          />
        )
      }
    />
  );
};
