import { useTranslation } from 'react-i18next';
import { SvgIcon, Text } from '../../atoms';
import styles from './PaginationControls.module.css';
import { IPaginationResponse } from '../../utils/interfaces';
import { Icons } from '../../assets/icons';
import { PAGINATION_LIMIT__DEFAULT } from '../../config/consts';

export type PaginationControlsProps<T> = {
  paginationData: Awaited<IPaginationResponse<T>>['meta'] | undefined;
  onChangePageClick: (nextPage: number) => void;
  /**
   * @description Must be provided if your limit is different to the default (20)
   * @default 'PAGINATION_LIMIT__DEFAULT' (20)
   * @note should match 'paginationData.limit'. If it will not -- will be overriden by 'paginationData.limit'
   * @note should be provided alongside with 'paginationData' to show proper limit, while first fetch is loading
   * */
  limit?: number;
};

export const PaginationControls = <T extends {}>({
  paginationData,
  onChangePageClick: onChangePageClickProp,
  limit: limitParam = PAGINATION_LIMIT__DEFAULT,
}: PaginationControlsProps<T>) => {
  const { t } = useTranslation();

  const limit = paginationData?.limit ?? limitParam;
  const pageFirstItemIndex = ((paginationData?.currentPage ?? 1) - 1) * limit;
  const pageLastItemIndex = Math.min(
    pageFirstItemIndex + limit - 1,
    paginationData?.totalCount ?? 0,
  );
  const totalCount = paginationData?.totalCount ?? 0;

  const pageFirstItemCount = Math.min(pageFirstItemIndex + 1, totalCount);
  const pageLastItemCount = Math.min(pageLastItemIndex + 1, totalCount);

  const canDecrementOffset = pageFirstItemIndex > 0;
  const canIncrementOffset = pageLastItemIndex < totalCount - 1;

  const onChangePageClick = (increment: number) => {
    const nextPage = (paginationData?.currentPage ?? 1) + increment;
    if (nextPage < 1) {
      return;
    }
    onChangePageClickProp?.(nextPage);
  };

  return (
    <div className={styles.paginationControls}>
      <Text
        text={t('paginationControls_pageInfo', {
          pageFirstItemCount,
          pageLastItemCount,
          totalCount,
        })}
        fontSize="body"
        fontWeight="medium"
      />
      <SvgIcon
        onClick={() => onChangePageClick(-1)}
        disabled={!canDecrementOffset}
        icon={<Icons.ChevronLeft className={styles.icon} />}
      />
      <SvgIcon
        onClick={() => onChangePageClick(+1)}
        disabled={!canIncrementOffset}
        icon={<Icons.ChevronRight className={styles.icon} />}
      />
    </div>
  );
};
