import { ReactElement } from 'react';
import { Icons } from '../../assets/icons';
import { Text } from '../../atoms';
import { getTextVariant } from './Checkbox.helper';
import styles from './Checkbox.module.css';
import { Skeleton } from '../Skeleton';

export interface CheckboxProps {
  text: string;
  textKey?: string;
  checked: boolean;
  isAlternativeCorrect?: boolean;
  isDisabled?: boolean;
  icon?: ReactElement;
  size?: 'small' | 'large';
  checkboxPosition?: 'left' | 'right';
  onChange: (checked: boolean) => void;
  isLoading?: boolean;
}

export const Checkbox = (props: CheckboxProps) => {
  const {
    text,
    textKey,
    checked,
    isAlternativeCorrect,
    isDisabled = false,
    size = 'large',
    checkboxPosition = 'left',
    onChange,
    icon,
    isLoading = false,
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  const textVariant = getTextVariant(checked, isAlternativeCorrect);
  const isBehaviourDisabled = isAlternativeCorrect !== undefined || isDisabled;

  const getText = () => {
    return (
      <div className={styles.textContainer}>
        {icon}
        <Text
          text={text}
          textKey={textKey}
          fontSize={size === 'small' ? 'body' : 'bodyLarge'}
          fontWeight={size === 'small' ? 'regular' : 'medium'}
          variant={textVariant}
        />
        {isLoading && <Skeleton type="bodyLarge" fillerChars={2} />}
      </div>
    );
  };

  const getCheckbox = () => {
    return (
      <div className={styles.checkboxContainer}>
        <input
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          className={`${styles.checkbox} ${styles[checkboxPosition]} ${styles[size]} ${styles[textVariant ?? 'waiting']}`}
          disabled={isBehaviourDisabled}
        ></input>

        {isAlternativeCorrect === undefined || textVariant === 'success' ? (
          <Icons.Checkmark
            className={`${styles.checkboxIcon} ${styles[size]} ${styles[checkboxPosition]}`}
          />
        ) : (
          <Icons.Dash
            className={`${styles.checkboxIcon} ${styles[size]} ${styles[checkboxPosition]}`}
          />
        )}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <label
        className={`${styles.label} ${isBehaviourDisabled ? styles.disabled : ''}`}
      >
        <div className={styles.highlightUnderlay} />
        {checkboxPosition === 'left' ? (
          <>
            {getCheckbox()}
            {getText()}
          </>
        ) : (
          <>
            {getText()}
            {getCheckbox()}
          </>
        )}
      </label>
    </div>
  );
};
