import { useState } from 'react';
import { ZoomLevel } from '../types/uiTypes';

const zoomLevels: ZoomLevel[] = [0, 1, 2];

/**
 * Zoom Level Mapper for CSS Classes.
 * @typedef {Object} ZoomInMapper
 * @property {string} 0 - No zoom applied.
 * @property {string} 1 - Applies moderate zoom styling via CSS (e.g., transform: scale(1.2)).
 * @property {string} 2 - Applies maximum zoom styling via CSS (e.g., transform: scale(1.5)).
 */

export const zoomInMapper: Record<ZoomLevel, string> = {
  0: '',
  1: 'zoomedLevel1',
  2: 'zoomedLevel2',
};

interface UseZoomOutput {
  zoomLevel: ZoomLevel;
  toggleZoom: (e: React.SyntheticEvent) => void;
  isLastZoomLevel: boolean;
  setZoomLevel: React.Dispatch<React.SetStateAction<ZoomLevel>>;
}

export const useZoom = (): UseZoomOutput => {
  const [zoomLevel, setZoomLevel] = useState<ZoomLevel>(0);

  const toggleZoom = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    const currentIndex = zoomLevels.indexOf(zoomLevel);
    const nextIndex = (currentIndex + 1) % zoomLevels.length;
    setZoomLevel(zoomLevels[nextIndex]);
  };

  const isLastZoomLevel = zoomLevel === zoomLevels[zoomLevels.length - 1];

  return { zoomLevel, toggleZoom, isLastZoomLevel, setZoomLevel };
};
