import { Icons } from '../../assets/icons';
import { Images } from '../../assets/images';
import { Button, Card, Text } from '../../atoms';
import { UserCourseResult } from '../../utils/interfaces';
import styles from './CourseCompletion.module.css';

interface CourseCompletionProps {
  course: UserCourseResult;
  onBackToCourses: () => void;
}

export const CourseCompletion = (props: CourseCompletionProps) => {
  const { course, onBackToCourses } = props;
  return (
    <div className={styles.container}>
      <div className={styles.centeredContent}>
        <img
          src={Images.WomanThatJumpsAtHome}
          alt="Celebration finished course"
          className={styles.completeIllustration}
        />
        <Text
          text="Course Complete!"
          textKey="course_complete"
          variant="complementary"
          fontSize="h2"
          fontWeight="bold"
        />
        <Text
          text="We're very proud of you"
          textKey="course_complete_2"
          variant="complementary"
          fontSize="h2"
          fontWeight="bold"
        />
        <div className={styles.card}>
          <Card>
            <div className={styles.cardUnderlay}>
              <Card />
            </div>

            <Icons.CheckmarkRoundFilled className={styles.icon} />
            <div className={styles.courseName}>
              <Text
                text={course.title}
                textKey={course.titleKey}
                fontSize="h3"
                fontWeight="bold"
              />
            </div>
            <Button
              text="Back to courses"
              textKey="course_button_backToCourses"
              type="secondary"
              size="full"
              onClick={onBackToCourses}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};
