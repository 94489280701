import { useState } from 'react';
import { ChevronDown } from '../../assets/icons/ChevronDown';
import { Avatar, Text } from '../../atoms';
import styles from './UserCard.module.css';
import { ChevronUp } from '../../assets/icons/ChevronUp';
import { StaticMenu } from '../StaticMenu';
import { Icons } from '../../assets/icons';
import { getImageUrl } from '../../utils/frontendUtils';

interface UserCardProps {
  name: string;
  photoFileName?: string | null;
  hasBorder: boolean;
  expansionDirection?: 'up' | 'down';
  isInitiallyExpanded?: boolean;
  doLogout: () => void;
}

const menuOptions = [
  {
    key: 'log_out',
    text: 'Log out',
    textKey: 'user_logout',
    icon: <Icons.Door />,
  },
];

export const UserCard = (props: UserCardProps) => {
  const {
    name,
    photoFileName,
    hasBorder,
    expansionDirection = 'down',
    isInitiallyExpanded,
    doLogout,
  } = props;
  const photoUrl = photoFileName
    ? getImageUrl(photoFileName, 'small')
    : undefined;

  const [isExpanded, setIsExpanded] = useState<boolean>(
    isInitiallyExpanded || false,
  );

  const onChange = (key: string) => {
    if (key === 'log_out') {
      doLogout();
    }
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={`${styles.container} ${isExpanded ? styles.expanded : ''} ${hasBorder ? styles.bordered : ''}`}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Avatar url={photoUrl} />
        <Text
          text={name}
          fontSize="titleLarge"
          fontWeight="bold"
          truncate="oneLine"
        />
        <div className={styles.expandIconContainer}>
          {isExpanded ? (
            <ChevronUp className={styles.expandIcon} />
          ) : (
            <ChevronDown className={styles.expandIcon} />
          )}
        </div>
      </div>
      <div
        className={`${styles.staticMenu} ${styles[expansionDirection]} ${isExpanded ? styles.expanded : ''}`}
      >
        <StaticMenu menuOptions={menuOptions} onChange={onChange} />
      </div>
    </div>
  );
};
