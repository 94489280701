import React, { useEffect, useMemo, useState } from 'react';
import styles from './Courses.module.css';
import { UserCourseResult } from '../../utils/interfaces';
import {
  CourseCard,
  HeroSection,
  PaginationControls,
  Skeleton,
} from '../../molecules';
import { PAGINATION_LIMIT__COURSES } from '../../config/consts';
import { Icons } from '../../assets/icons';
import { InputField, Text } from '../../atoms';
import { CoursePaginationResponse } from '../../utils/interfaces/academy-api/icoursePaginationResponse';
import { CourseCount } from '../../utils/hooks';
import {
  CourseFilterDisplay,
  CourseFilter,
} from '../../molecules/CourseFilterDisplay';
import { useTranslation } from 'react-i18next';
import { BottomSheet } from '../../atoms/BottomSheet';
import { getTranslationKey } from '../../i18n';

interface CoursesProps {
  name: string;
  courses: UserCourseResult[] | undefined;
  highlightedCourse?: UserCourseResult | null;
  paginationData?: Awaited<CoursePaginationResponse>['meta'];
  filter: CourseFilter;
  courseCount?: CourseCount;
  onApplyFilter: (filter: CourseFilter) => void;
  onCourseCardClick: (course: UserCourseResult) => void;
  onChangePageClick: (nextPage: number) => void;
  onChangeSearch: (nextSearchText: string) => void;
}

export const Courses: React.FC<CoursesProps> = ({
  name,
  courses,
  highlightedCourse,
  paginationData,
  filter,
  courseCount,
  onApplyFilter,
  onCourseCardClick: onCourseCardClickProp,
  onChangePageClick,
  onChangeSearch,
}) => {
  const [searchInputState, setSearchInputState] = useState<string | undefined>(
    paginationData?.search ?? '',
  );

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const selectedFilters = useMemo(() => {
    return (
      filter.statuses.length + filter.tags.length + (filter.isPremium ? 1 : 0)
    );
  }, [filter]);

  useEffect(() => {
    setSearchInputState(paginationData?.search ?? '');
  }, [paginationData?.search]);

  const onSearchChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    const nextSearchText = changeEvent.target.value;
    setSearchInputState(nextSearchText);
    onChangeSearch?.(nextSearchText);
  };

  const onCourseCardClick = (course: UserCourseResult) => {
    onCourseCardClickProp(course);
  };

  const notFoundBySearchComponent = () => {
    return (
      <div className={styles.notFoundBySearchCourseContainer}>
        <Icons.GlobalSearch />
        <Text
          text="Nothing found"
          textKey="courses_not_found"
          variant="discrete"
          fontSize="body"
          fontWeight="medium"
        />
        <Text
          text="Check your search request or try again"
          textKey="courses_not_found_try_again"
          variant="discrete"
          fontSize="body"
          fontWeight="medium"
        />
      </div>
    );
  };

  const emptyCoursesComponent = () => {
    return (
      <div className={styles.noCourseContainer}>
        <Icons.Cards />
        <Text
          text="No courses here yet"
          textKey="courses_none"
          variant="discrete"
          fontSize="body"
          fontWeight="medium"
        />
        <Text
          text="Check back later or contact your administrator"
          textKey="courses_none_contact"
          variant="discrete"
          fontSize="body"
          fontWeight="medium"
        />
      </div>
    );
  };

  return (
    <div className={styles.page}>
      <HeroSection
        course={highlightedCourse}
        name={name}
        onGoToCourse={() =>
          highlightedCourse ? onCourseCardClick(highlightedCourse) : undefined
        }
      />
      <div className={styles.header}>
        <Text
          text="Explore courses"
          textKey="courses_title"
          fontSize="h2"
          fontWeight="bold"
        />
        <div className={styles.search}>
          <InputField
            helperText="Search for courses"
            helperTextKey="courses_searchBar"
            type="search"
            name="search"
            value={searchInputState}
            onChange={onSearchChange}
          />
        </div>
        <div className={styles.filterBarButton}>
          <div
            className={styles.titlesContainer}
            onClick={() => setIsOpen(true)}
          >
            <Text
              text="Filters"
              textKey="course_filter_header"
              fontSize="bodyLarge"
              fontWeight="medium"
              variant="primary"
            />
            <Text
              text={t(
                getTranslationKey(
                  selectedFilters,
                  'courses_filters_selected_singular',
                  'courses_filters_selected_plural',
                ),
                { count: selectedFilters },
              )}
              fontWeight="medium"
              variant={!!selectedFilters ? 'attention' : 'discrete'}
            />
          </div>
          <Icons.ChevronDown className={styles.expandIcon} />
        </div>
        <BottomSheet isOpen={isOpen} setIsOpen={setIsOpen}>
          <CourseFilterDisplay
            courseCount={courseCount}
            onApply={onApplyFilter}
            filter={filter}
            isLoading={!courses}
            variant="mobile"
            dismiss={() => setIsOpen(false)}
          />
        </BottomSheet>
      </div>
      <div className={styles.content}>
        <div className={styles.filter}>
          <CourseFilterDisplay
            courseCount={courseCount}
            onApply={onApplyFilter}
            filter={filter}
            isLoading={!courses}
          />
        </div>
        {!courses && (
          <div className={styles.coursesList}>
            <div className={styles.coursesListItems}>
              <Skeleton type="courseCard" />
              <Skeleton type="courseCard" />
            </div>
          </div>
        )}
        {courses &&
          (courses.length > 0 ? (
            <div className={styles.coursesList}>
              <div className={styles.coursesListItems}>
                {courses.map((course) => (
                  <CourseCard
                    key={'CourseCard-' + course._id}
                    course={course}
                    onStartCourse={() => onCourseCardClick(course)}
                  />
                ))}
              </div>
              {(paginationData?.totalCount ?? 0) >
                PAGINATION_LIMIT__COURSES && (
                <div className={styles.pagination}>
                  <PaginationControls
                    paginationData={paginationData}
                    onChangePageClick={onChangePageClick}
                    limit={PAGINATION_LIMIT__COURSES}
                  />
                </div>
              )}
            </div>
          ) : paginationData?.search ? (
            notFoundBySearchComponent()
          ) : (
            emptyCoursesComponent()
          ))}
      </div>
    </div>
  );
};
