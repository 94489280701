export const LeftArrow = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 7.72887C20.0006 8.28115 19.5534 8.72937 19.0011 8.72998L1.00111 8.75C0.448828 8.75061 0.000614918 8.3034 6.32067e-07 7.75111C-0.000613653 7.19883 0.446603 6.75061 0.998888 6.75L18.9989 6.72998C19.5512 6.72937 19.9994 7.17658 20 7.72887Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.95711 0.792893C8.34763 1.18342 8.34763 1.81658 7.95711 2.20711L2.41421 7.75L7.95711 13.2929C8.34763 13.6834 8.34763 14.3166 7.95711 14.7071C7.56658 15.0976 6.93342 15.0976 6.54289 14.7071L0.292893 8.45711C-0.0976311 8.06658 -0.0976311 7.43342 0.292893 7.04289L6.54289 0.792893C6.93342 0.402369 7.56658 0.402369 7.95711 0.792893Z"
        fill="currentColor"
      />
    </svg>
  );
};
