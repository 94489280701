import { Button, Card, Text } from '../../atoms';
import styles from './PasswordRecoverySent.module.css';
import { useNavigate } from 'react-router-dom';
import { LoginHeader } from '../LoginHeader';
import { useState } from 'react';
import { Icons } from '../../assets/icons';
import { getForgotPasswordInstructionsSentErrorKey } from '../ForgotPassword/ForgotPassword.helpers';
import { useSnack } from '../../providers';

interface PasswordRecoverySentProps {
  email: string;
  onResendInstructions: (email: string) => void;
}

export const PasswordRecoverySent = ({
  email,
  onResendInstructions,
}: PasswordRecoverySentProps) => {
  const navigate = useNavigate();
  const { showSnack } = useSnack();
  const [loading, setLoading] = useState(false);

  const goBackToLogin = () => {
    navigate('/login');
  };

  const resendInstructions = async () => {
    try {
      setLoading(true);
      await onResendInstructions(email);
      showSnack({
        text: 'Instructions email sent!',
        textKey: 'forgotPassword_instructions_email_sent',
        type: 'success',
        icon: <Icons.CheckmarkRound />,
      });
    } catch (error: any) {
      const errorKey = getForgotPasswordInstructionsSentErrorKey(error);
      showSnack({
        textKey: errorKey,
        type: 'error',
        icon: <Icons.CrossRound />,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <Card>
        <div className={styles.childContainer}>
          <LoginHeader />
          <span>
            <Text
              text="Password recovery instructions sent to"
              textKey="forgotPassword_instructions_sent"
              fontSize="bodyLarge"
              fontWeight="regular"
            />
            <Text text={` ${email}.`} fontSize="bodyLarge" fontWeight="bold" />
          </span>

          <br />

          <Text
            text="Check your inbox"
            textKey="forgotPassword_check_inbox"
            fontSize="bodyLarge"
            fontWeight="regular"
          />

          <div className={`${styles.button} ${styles.firstButton}`}>
            <Button
              text="Resend instructions"
              textKey="forgotPassword_resend_instructions"
              onClick={resendInstructions}
              size="full"
              icon={
                loading ? (
                  <Icons.Loader className={styles.loaderIcon} />
                ) : undefined
              }
              disabled={loading}
            />
          </div>
          <div className={styles.button}>
            <Button
              text="Back to login"
              textKey="forgotPassword_to_login"
              onClick={goBackToLogin}
              size="full"
              type="secondary"
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
