import BlueBackground from './BackgroundBlue.png';
import WomanThatJumpsAtHome from './woman-that-jumps-at-home.svg';
import CosafeMentoringSession from './cosafe-mentoring-session.png';
import NotFound from './not-found.svg';
import BackgroundInCosafeColors from './BackgroundInCosafeColors.svg';
import PersonWithComputer from './person-with-computer.jpg';

export const Images = {
  BlueBackground,
  WomanThatJumpsAtHome,
  CosafeMentoringSession,
  NotFound,
  BackgroundInCosafeColors,
  PersonWithComputer,
};
