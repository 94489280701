import styles from './NotFoundPage.module.css';
import { useFetchCourses } from '../../utils/hooks';
import { NotFound } from '../../organisms';

export const NotFoundPage: React.FC = () => {
  const { highlightedCourse } = useFetchCourses();

  return (
    <div className={styles.container} data-testid="not-found-page">
      <NotFound highlightedCourse={highlightedCourse} />
    </div>
  );
};
