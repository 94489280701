export const getTextVariant = (
  checked: boolean,
  isAlternativeCorrect?: boolean,
) => {
  if (isAlternativeCorrect === undefined) {
    return undefined;
  }

  if (isAlternativeCorrect) {
    return 'success';
  } else if (checked) {
    return 'danger';
  }

  return undefined;
};
