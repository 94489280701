import { ReactElement } from 'react';
import styles from './StaticMenu.module.css';
import { Text } from '../../atoms';
import { useTranslation } from 'react-i18next';

interface MenuOptionTextKey {
  textKey: string;
  text?: string;
}

interface MenuOptionText {
  textKey?: string;
  text: string;
}

export type MenuOption = (MenuOptionTextKey | MenuOptionText) & {
  key: string;
  icon: ReactElement;
};

interface StaticMenuProps {
  menuOptions: MenuOption[];
  selected?: string;
  onChange: (key: string) => void;
}

export const StaticMenu = (props: StaticMenuProps) => {
  const { menuOptions, selected, onChange } = props;
  const { t } = useTranslation();
  return menuOptions.map((option, index) => {
    let optionText = option.textKey ? t(option.textKey) : option.text!;
    return (
      <button
        key={option.key}
        className={`${styles.menuOption} ${selected === option.key ? styles.selected : ''} ${index === 0 ? styles.first : ''} ${index === menuOptions.length - 1 ? styles.last : ''}`}
        onClick={() => onChange(option.key)}
      >
        {option.icon}
        <span className={styles.text}>
          <Text
            text={optionText}
            fontSize="titleLarge"
            fontWeight="regular"
            variant={selected === option.key ? 'attention' : 'none'}
          />
        </span>
      </button>
    );
  });
};
