import { LoginForm } from '../../../../organisms/Login';
import { saveItem } from '../../../storage';
import { legacyAccountApi } from '../legacyAccountApi';

interface SSOParams {
  organization: Org;
  url: string;
}

interface Org {
  id: number;
  name: string;
  parentId: number | null;
}

enum clientType {
  Web = '1',
}

export const login = async ({ email, password }: LoginForm) => {
  const csrfToken = await getAntiForgeryToken();

  return legacyAccountApi.request({
    method: 'POST',
    resourcePath: 'login',
    data: {
      email,
      password,
      clientType: clientType.Web,
    },
    customHeaders: { 'X-XSRF-Token': csrfToken },
  });
};

export const ssoLogin = async ({
  email,
  code,
  url,
  provider,
}: {
  email: string | null;
  code: string;
  url: string;
  provider?: string;
}) => {
  const csrfToken = await getAntiForgeryToken();
  return legacyAccountApi.request({
    method: 'POST',
    resourcePath: 'login',
    data: {
      email,
      oauth_code: code,
      redirect_url: url,
      sso_provider: provider,
      clientType: clientType.Web,
    },
    customHeaders: { 'X-XSRF-Token': csrfToken },
  });
};

export const getAccountInfo = async () => {
  return legacyAccountApi.request({
    method: 'GET',
    resourcePath: 'info/app',
  });
};

export const logoutRequest = async () => {
  const csrfToken = await getAntiForgeryToken();

  return legacyAccountApi.request({
    method: 'POST',
    resourcePath: 'logout',
    customHeaders: { 'X-XSRF-Token': csrfToken },
  });
};

export const getAntiForgeryToken = async () => {
  const csrf = await legacyAccountApi.request<any>({
    method: 'GET',
    resourcePath: 'AntiForgeryToken',
  });
  saveItem('csrf', csrf.requestVerificationToken);
  return csrf.requestVerificationToken;
};

export const fetchSsoUrl = async (url: string) => {
  return await legacyAccountApi.request<SSOParams>({
    method: 'GET',
    resourcePath: url,
  });
};

export const sendRecoveryEmail = async (email: string) => {
  const csrfToken = await getAntiForgeryToken();

  return await legacyAccountApi.request({
    method: 'POST',
    resourcePath: 'Password/Token',
    customHeaders: { 'X-XSRF-Token': csrfToken },
    data: { email },
  });
};

export const validateToken = async (userId: string, token: string) => {
  return await legacyAccountApi.request<any>({
    method: 'GET',
    resourcePath: 'Password/validatetoken',
    queryParams: {
      userId,
      token,
    },
  });
};

export const resetPassword = async (
  userId: string,
  token: string,
  password: string,
) => {
  const csrfToken = await getAntiForgeryToken();

  return await legacyAccountApi.request<any>({
    method: 'POST',
    resourcePath: 'Password/Reset',
    customHeaders: { 'X-XSRF-Token': csrfToken },
    data: { userId, token, password },
  });
};
