export const SwedenFlag = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_112_247)">
        <rect width="24" height="24" rx="12" fill="#0275B0" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.3998 14.4H-3.2002V9.6H6.3998V0H11.1998V9.6H30.3998V14.4H11.1998V24H6.3998V14.4Z"
          fill="#FED200"
        />
      </g>
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="11.5"
        stroke="black"
        strokeOpacity="0.1"
      />
      <defs>
        <clipPath id="clip0_112_247">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
