import { Button, Card, Text } from '../../atoms';
import styles from './PasswordChanged.module.css';
import { useNavigate } from 'react-router-dom';
import { LoginHeader } from '../LoginHeader';

export const PasswordChanged = () => {
  const navigate = useNavigate();

  const goBackToLogin = () => {
    navigate('/login');
  };

  return (
    <div className={styles.container}>
      <Card>
        <div className={styles.childContainer}>
          <LoginHeader />

          <Text
            text="Password successfully changed. You can now log into the Cosafe Academy."
            textKey="passwordReset_changed_success"
            fontSize="bodyLarge"
            fontWeight="regular"
          />
          <div className={styles.button}>
            <Button
              text="Back to login"
              textKey="forgotPassword_to_login"
              onClick={goBackToLogin}
              size="full"
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
