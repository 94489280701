import React from 'react';
import styles from './CircularProgressBar.module.css';
import { Text } from '../../atoms';

const CIRCLE_RADIUS = 35;
const CIRCLE_STROKE_WIDTH = 6;

export const CircularProgressBar: React.FC<{
  value: number;
  maxValue: number;
  radius?: number;
  strokeWidth?: number;
}> = ({ value, maxValue, radius, strokeWidth }) => {
  const circleRadius = radius || CIRCLE_RADIUS;
  const circleStrokeWidth = strokeWidth || CIRCLE_STROKE_WIDTH;
  const circleNormalizedRadius = circleRadius - circleStrokeWidth * 0.5;
  const circleCircumference = circleNormalizedRadius * 2 * Math.PI;
  const strokeDashoffset = maxValue
    ? circleCircumference - (value / maxValue) * circleCircumference
    : 0;

  return (
    <div className={styles.container}>
      <svg
        height={circleRadius * 2}
        width={circleRadius * 2}
        className={`${styles.circularProgress} ${value === maxValue ? styles.success : styles.fail}`}
      >
        <circle
          stroke="currentColor"
          fill="transparent"
          strokeWidth={circleStrokeWidth}
          strokeDasharray={circleCircumference + ' ' + circleCircumference}
          style={{ strokeDashoffset }}
          strokeLinecap="round"
          r={circleNormalizedRadius}
          cx={circleRadius}
          cy={circleRadius}
        />
      </svg>
      <div className={styles.textContainer}>
        <Text
          text={`${value} / ${maxValue}`}
          fontSize="titleLarge"
          fontWeight="bold"
        />
      </div>
    </div>
  );
};
