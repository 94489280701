import { CourseFilter } from '../../molecules/CourseFilterDisplay';
import {
  defaultCourseFilter,
  defaultPage,
  defaultSearchText,
} from '../../utils/hooks';
import { CourseStatus, CourseTag } from '../../utils/interfaces';

const getListFromSearchParams = (
  key: string,
  searchParams: URLSearchParams,
): string[] => {
  if (!searchParams.get(key)?.length) {
    return [];
  }
  return searchParams.get(key)?.split(',') ?? [];
};

export const extractSearchParams = (
  searchParams: URLSearchParams,
): {
  filter: CourseFilter;
  page: string;
  searchText: string;
} => {
  const useDefault = searchParams?.size === 0;
  if (useDefault) {
    return {
      filter: defaultCourseFilter,
      page: defaultPage,
      searchText: defaultSearchText,
    };
  }

  return {
    filter: {
      statuses: getListFromSearchParams('course_status', searchParams).map(
        (status) => status as keyof typeof CourseStatus,
      ) as CourseStatus[],
      tags: getListFromSearchParams('tags', searchParams).map(
        (tag) => tag as keyof typeof CourseTag,
      ) as CourseTag[],
      isPremium: searchParams.get('type') === 'Premium',
    },
    page: searchParams.get('page') ?? defaultPage,
    searchText: searchParams.get('search') ?? defaultSearchText,
  };
};
