import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { CourseCompletion } from '../../organisms';
import { fetchCourseById } from '../../redux/CourseSlice/actionCreators';
import { useEffect } from 'react';
import { CourseStatus } from '../../utils/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { selectCourse } from '../../redux/CourseSlice';
import { AppDispatch } from '../../store';

export const CourseCompletionPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { courseId } = useParams<{
    courseId: string;
  }>();

  const course = useSelector(selectCourse);

  useEffect(() => {
    if (courseId) {
      dispatch(fetchCourseById(courseId));
    }
    //eslint-disable-next-line
  }, [courseId]);

  // Check if the required params are undefined
  if (!courseId) {
    return <Navigate to="/404" replace />;
  }

  if (course && course.status !== CourseStatus.Completed) {
    return <Navigate to="/404" replace />;
  }

  const onBackToCourses = () => {
    navigate('/');
  };

  return (
    <div data-testid="course-completion-page">
      {course && (
        <CourseCompletion course={course} onBackToCourses={onBackToCourses} />
      )}
    </div>
  );
};
