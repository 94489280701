import { SnackbarProps } from '../../atoms/Snackbar';

export type ShowSnackOptions = {
  /**
   * @default '3000'
   * @min '1500'
   * */
  duration_ms?: number;
  /**
   * @description Make a Snack not to auto-fade and stay in a list
   * @default 'false'
   * @note If user will over the cursor over snacks, all current snacks will became persistable. New ones will be fadeable again, with old ones stay persistant.
   */
  persist?: boolean;
};

export type QueuedSnackParams = Omit<SnackbarProps, 'isShown' | 'onClose'>;

export type QueuedSnack = {
  id: string;
  snackData: {
    snackProps: QueuedSnackParams;
    options?: ShowSnackOptions;
  };
  methods: {
    close: () => void;
    cancelClosing: () => void;
  };
  listeners: {
    /** Not a modulare listener with multiple listeners. Is being replaced by only one listener */
    willBeClosed: () => void;
  };
};

export const SNACK_DURATION_DEFAULT_MS = 3000;
export const SNACK_DURATION_MIN_MS = 1500;
const SNACK_DURATION_ANIMATION_CLOSING_DEFAULT_MS = 500;

const defaultOptions: ShowSnackOptions = {
  duration_ms: SNACK_DURATION_DEFAULT_MS,
  persist: false,
};

export const createQueuedSnack = (
  removeQueuedSnack: (snackId: string) => void,
  snackProps: QueuedSnackParams,
  showSnackOptions?: ShowSnackOptions,
): QueuedSnack => {
  const options: ShowSnackOptions = {
    duration_ms:
      typeof showSnackOptions?.duration_ms === 'number'
        ? Math.max(SNACK_DURATION_MIN_MS, showSnackOptions.duration_ms)
        : defaultOptions.duration_ms,
    persist: showSnackOptions?.persist ?? defaultOptions.persist,
  };

  const newSnackId = 'snackId_' + Math.random();
  const newSnack: Omit<QueuedSnack, 'methods'> = {
    id: newSnackId,
    snackData: { snackProps, options },
    listeners: {
      willBeClosed: () => undefined,
    },
  };

  const [task, willBeClosedTask] = options.persist
    ? [undefined, undefined]
    : createTasks(
        newSnackId,
        removeQueuedSnack,
        options.duration_ms!,
        newSnack.listeners,
      );

  const clearTimers = () => {
    clearTimeout(task);
    clearTimeout(willBeClosedTask);
  };

  const close = () => {
    clearTimers();
    removeQueuedSnack(newSnackId);
  };

  const cancelClosing = () => {
    clearTimers();
  };

  const snack: QueuedSnack = {
    ...newSnack,
    methods: {
      close,
      cancelClosing,
    },
  };

  return snack;
};

const createTasks = (
  snackId: string,
  removeQueuedSnack: (snackId: string) => void,
  duration_ms: number,
  listeners: QueuedSnack['listeners'],
) => {
  const task = setTimeout(() => {
    removeQueuedSnack(snackId);
  }, duration_ms!);

  const willBeClosedTask = setTimeout(() => {
    listeners.willBeClosed();
  }, duration_ms - SNACK_DURATION_ANIMATION_CLOSING_DEFAULT_MS);

  return [task, willBeClosedTask];
};
