import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useLayoutEffect } from 'react';

export const usePreventScroll = (
  enabled: boolean,
  contentWrapperClass: string,
): void => {
  useLayoutEffect(() => {
    if (typeof document === 'undefined' || !enabled) {
      return;
    }
    const scrollableElement = document.querySelector(
      `.${contentWrapperClass}`,
    ) as HTMLElement;
    disableBodyScroll(scrollableElement);

    return () => {
      enableBodyScroll(scrollableElement);
    };
  }, [enabled]);
};
