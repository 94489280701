import styles from './SidebarLanguageSelector.module.css';
import { Icons } from '../../assets/icons';
import { Text } from '../../atoms/Text';
import { getCurrentAppLanguage, updateLanguage } from '../../i18n';
import { MenuOption } from '../StaticMenu';
import { getLanguages } from '../../utils/frontendUtils';
import { IconButton } from '../../atoms/IconButton';
import { AppLanguage, availableAppLanguages } from '@mono/common';
import ButtonStyles from '../../atoms/MenuItem/MenuItem.module.css';

const languages = getLanguages(styles.flag);

export interface SidebarLanguageSelectorProps {
  handleClose: () => void;
}

export const SidebarLanguageSelector = (
  props: SidebarLanguageSelectorProps,
) => {
  const { handleClose } = props;

  const currentAppLanguage = getCurrentAppLanguage();

  const handleLanguageSelect = (appLanguage: AppLanguage) => {
    updateLanguage(appLanguage);
    handleClose();
  };

  const renderLanguageMenu = () => {
    const menuOptions: MenuOption[] = availableAppLanguages.map(
      (appLanguage: AppLanguage) => {
        return {
          key: appLanguage as string,
          text: languages[appLanguage].name,
          icon: languages[appLanguage].icon,
        } as MenuOption;
      },
    );
    return menuOptions.map((option, index) => (
      <div
        className={`${styles.languageListItem} ${currentAppLanguage === option.key && styles.languageSelected}`}
        key={option.key}
        onClick={() => handleLanguageSelect(option.key as AppLanguage)}
      >
        {option.icon}
        <Text text={option.text!} fontSize="body" fontWeight="medium" />
      </div>
    ));
  };

  const getSelectedLeftIcon = () => {
    return (
      <div className={styles.innerRow}>
        <Icons.ChevronLeft
          className={`${styles.icon} ${ButtonStyles.chevronLeftIcon}`}
        />
        <Text
          text="Select language"
          textKey="select_language"
          fontSize="body"
          fontWeight="medium"
        />
      </div>
    );
  };

  return (
    <div>
      <IconButton
        size="full"
        type="menu"
        rightIcon={languages[currentAppLanguage].icon}
        leftIcon={getSelectedLeftIcon()}
        className={styles.selectedLanguage}
        onClick={handleClose}
      />
      {renderLanguageMenu()}
    </div>
  );
};
