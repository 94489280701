import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateUser } from './redux/Login/LoginSlice/actionCreators';
import { AppDispatch } from './store';
import { AppRoutes } from './AppRoutes';
import { getItem } from './utils/storage';

const App: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const user = getItem('user');
    if (user) {
      dispatch(updateUser());
    }
  }, []);

  return (
    <Router>
      <AppRoutes />
    </Router>
  );
};

export default App;
