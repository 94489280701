import { useTranslation } from 'react-i18next';
import styles from './ScrollableText.module.css';

interface ScrollableTextProps {
  text?: string;
  textKey?: string;
}

export const ScrollableText = (props: ScrollableTextProps) => {
  const { t } = useTranslation();
  const { text, textKey } = props;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {textKey ? t(textKey, text ?? '') : text}
      </div>
    </div>
  );
};
