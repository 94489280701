export const NetherlandsFlag = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_271_259)">
        <g clipPath="url(#clip1_271_259)">
          <mask
            id="mask0_271_259"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="20"
          >
            <path
              d="M20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_271_259)">
            <path
              d="M20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-4 13.3333H24V20H-4V13.3333Z"
              fill="#1E448D"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-4 0H24V6.66667H-4V0Z"
              fill="#B01923"
            />
          </g>
        </g>
        <rect
          x="0.5"
          y="0.5"
          width="19"
          height="19"
          rx="9.5"
          stroke="black"
          strokeOpacity="0.1"
        />
      </g>
      <defs>
        <clipPath id="clip0_271_259">
          <rect width="20" height="20" rx="10" fill="white" />
        </clipPath>
        <clipPath id="clip1_271_259">
          <rect width="20" height="20" rx="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
