export const Envelope = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75003 2.00004C2.47607 2.00004 2.30475 2.21248 2.30475 2.41671V11.15C2.30475 11.5834 2.66357 11.9834 3.1667 11.9834H14.8334C15.3365 11.9834 15.6953 11.5834 15.6953 11.15V4.97504C15.6953 4.5148 16.0556 4.14171 16.5 4.14171C16.9445 4.14171 17.3048 4.5148 17.3048 4.97504V11.15C17.3048 12.5583 16.1719 13.65 14.8334 13.65H3.1667C1.82816 13.65 0.695312 12.5583 0.695312 11.15V2.41671C0.695312 1.2376 1.64066 0.333374 2.75003 0.333374H16.5C16.8299 0.333374 17.1263 0.541803 17.248 0.859269C17.3697 1.17673 17.292 1.53895 17.0519 1.7732L16.5352 2.27737C16.2118 2.593 15.7025 2.57733 15.3977 2.24238C15.3313 2.16935 15.28 2.08718 15.2438 2.00004H2.75003Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6124 1.18565C16.8867 1.54774 16.8257 2.07157 16.476 2.35568L9.49686 8.02651C9.20481 8.26381 8.7939 8.26344 8.50225 8.02561L1.54811 2.35478C1.19894 2.07004 1.13878 1.5461 1.41374 1.18451C1.68869 0.822924 2.19465 0.760624 2.54382 1.04536L9.001 6.31094L15.4825 1.04446C15.8322 0.760357 16.338 0.823571 16.6124 1.18565Z"
        fill="currentColor"
      />
    </svg>
  );
};
