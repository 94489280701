import { getCardClassNames } from './Card.helpers';
import styles from './Card.module.css';

export interface CardProps {
  children?: React.ReactNode | React.ReactNode[];
  image?: string;
  disabled?: boolean;
  border?: 'dark' | 'none';
  onClick?: () => void;
}

export const Card = (props: CardProps) => {
  const { children, image, disabled, onClick, border } = props;
  return (
    <div
      className={getCardClassNames({
        onClick,
        disabled,
        border,
      })}
      onClick={disabled ? undefined : onClick}
    >
      {image && (
        <div className={styles.imageContainer}>
          <img className={styles.image} src={image} alt="" />
        </div>
      )}
      <div className={styles.childContainer}>{children}</div>
    </div>
  );
};
