export const Cards = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M43.8199 20.88L41.8599 29.24C40.1799 36.46 36.8599 39.38 30.6199 38.78C29.6199 38.7 28.5399 38.52 27.3799 38.24L24.0199 37.44C15.6799 35.46 13.0999 31.34 15.0599 22.98L17.0199 14.6C17.4199 12.9 17.8999 11.42 18.4999 10.2C20.8399 5.36002 24.8199 4.06002 31.4999 5.64002L34.8399 6.42002C43.2199 8.38002 45.7799 12.52 43.8199 20.88Z"
        fill="currentColor"
      />
      <path
        d="M30.6202 38.7799C29.3802 39.6199 27.8202 40.3199 25.9202 40.9399L22.7602 41.9799C14.8202 44.54 10.6402 42.3999 8.06019 34.4599L5.50019 26.56C2.94019 18.62 5.06019 14.42 13.0002 11.86L16.1602 10.82C16.9802 10.56 17.7602 10.34 18.5002 10.2C17.9002 11.42 17.4202 12.9 17.0202 14.6L15.0602 22.98C13.1002 31.34 15.6802 35.4599 24.0202 37.4399L27.3802 38.24C28.5402 38.52 29.6202 38.6999 30.6202 38.7799Z"
        fill="currentColor"
      />
    </svg>
  );
};
