export const MagnifyingGlass = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.17901 2.33317C5.73283 2.33317 4.43235 2.9682 3.54201 3.97963L3.54106 3.9807C2.78802 4.83321 2.33317 5.95519 2.33317 7.18317C2.33317 9.86043 4.50591 12.0332 7.18318 12.0332C9.83457 12.0332 11.9911 9.90221 12.0326 7.26062C12.0302 7.23512 12.029 7.20928 12.029 7.18317C12.029 4.50591 9.85627 2.33317 7.17901 2.33317ZM13.6951 7.09355C13.6471 3.53709 10.7468 0.666504 7.17901 0.666504C5.23377 0.666504 3.48445 1.52292 2.29147 2.87785C1.27813 4.02528 0.666504 5.53641 0.666504 7.18317C0.666504 10.7809 3.58544 13.6998 7.18318 13.6998C10.7809 13.6998 13.6998 10.7809 13.6998 7.18317C13.6998 7.15289 13.6982 7.12299 13.6951 7.09355Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7815 11.7897C12.1071 11.4644 12.6347 11.4647 12.96 11.7903L17.0767 15.9111C17.4019 16.2367 17.4017 16.7643 17.0761 17.0896C16.7505 17.4149 16.2228 17.4146 15.8976 17.089L11.7809 12.9682C11.4556 12.6426 11.4559 12.115 11.7815 11.7897Z"
        fill="currentColor"
      />
    </svg>
  );
};
