export const RadioChecked = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 10C7.7614 10 10 7.7614 10 5C10 2.23857 7.7614 0 5 0C2.23857 0 0 2.23857 0 5C0 7.7614 2.23857 10 5 10ZM5 9C7.20915 9 9 7.20915 9 5C9 2.79086 7.20915 1 5 1C2.79086 1 1 2.79086 1 5C1 7.20915 2.79086 9 5 9ZM7 5C7 6.10455 6.10455 7 5 7C3.89543 7 3 6.10455 3 5C3 3.89543 3.89543 3 5 3C6.10455 3 7 3.89543 7 5Z"
        fill="currentColor"
      />
    </svg>
  );
};
