import React, { useState } from 'react';
import styles from './LanguageSelector.module.css';
import { getCurrentAppLanguage, updateLanguage } from '../../i18n';
import { GreatBritainFlag } from '../../assets/icons/flags/GreatBritainFlag';
import { SwedenFlag } from '../../assets/icons/flags/SwedenFlag';
import { NetherlandsFlag } from '../../assets/icons/flags/NetherlandsFlag';
import { MenuOption, StaticMenu } from '../StaticMenu';
import { AppLanguage, availableAppLanguages } from '@mono/common';

const languages = {
  en: {
    name: 'English (UK)',
    icon: <GreatBritainFlag className={styles.flag} />,
  },
  se: { name: 'Svenska', icon: <SwedenFlag className={styles.flag} /> },
  nl: {
    name: 'Nederlands',
    icon: <NetherlandsFlag className={styles.flag} />,
  },
};

interface LanguageSelectorProps {
  expansionDirection?: 'up' | 'down';
  isInitiallyExpanded?: boolean;
  hasBorder: boolean;
}

export const LanguageSelector = (props: LanguageSelectorProps) => {
  const { expansionDirection = 'down', isInitiallyExpanded, hasBorder } = props;

  const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded || false);
  const [currentAppLanguage, setCurrentAppLanguage] = useState<AppLanguage>(
    getCurrentAppLanguage(),
  );

  const onLanguageChosen = (appLanguage: AppLanguage) => {
    setCurrentAppLanguage(appLanguage);
    updateLanguage(appLanguage);
    setIsExpanded(false);
  };

  const renderLanguageMenu = () => {
    const menuOptions: MenuOption[] = availableAppLanguages.map(
      (appLanguage) => {
        return {
          key: appLanguage as string,
          text: languages[appLanguage].name,
          icon: languages[appLanguage].icon,
        } as MenuOption;
      },
    );
    return (
      <StaticMenu
        menuOptions={menuOptions}
        onChange={(key) => onLanguageChosen(key as AppLanguage)}
        selected={currentAppLanguage}
      />
    );
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={`${styles.container} ${isExpanded ? styles.expanded : ''} ${hasBorder ? styles.bordered : ''}`}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {languages[currentAppLanguage].icon}
      </div>
      {isExpanded && (
        <div className={`${styles.staticMenu} ${styles[expansionDirection]}`}>
          {renderLanguageMenu()}
        </div>
      )}
    </div>
  );
};
