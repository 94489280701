import { Text } from '../Text';
import StyledClass from './AcademyBadge.module.css';

export interface AcademyBadgeProps {
  type: 'light' | 'dark';
}

export const AcademyBadge = (props: AcademyBadgeProps) => {
  const { type } = props;
  return (
    <span className={`${StyledClass.badge} ${StyledClass[type]}`}>
      <Text
        text="Academy"
        variant={type === 'dark' ? 'complementary' : 'primary'}
        fontSize="label"
        fontWeight="bold"
        fontStyle="italic"
      />
    </span>
  );
};
