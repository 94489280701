export const Cross = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.297.293a1 1 0 0 1 1.414 0l13.996 14a1 1 0 1 1-1.414 1.414l-13.996-14a1 1 0 0 1 0-1.414Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15.707.293a1 1 0 0 1 0 1.414l-14 14a1 1 0 0 1-1.414-1.414l14-14a1 1 0 0 1 1.414 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
