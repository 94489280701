export const RightArrow = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5313 5.78613L11.3555 6.9674L14.0807 9.67983L2.74316 9.69125L2.74484 11.3579L14.0513 11.3466L11.372 14.0384L12.5533 15.2142L17.2563 10.4892L12.5313 5.78613Z"
        fill="currentColor"
      />
    </svg>
  );
};
