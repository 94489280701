import { Text } from '../../atoms';
import styles from './InfoSet.module.css';
import { Icons } from '../../assets/icons';

type InfoFieldIconType = 'clock' | 'document';

interface InfoField {
  icon: InfoFieldIconType;
  text: string;
}

interface InfoSetProps {
  infoFields: InfoField[];
}

export const InfoSet = (props: InfoSetProps) => {
  const { infoFields } = props;

  const getIcon = (iconType: InfoFieldIconType) => {
    switch (iconType) {
      case 'clock':
        return <Icons.Clock className={styles.icon} />;
      case 'document':
        return <Icons.Document className={styles.icon} />;
    }
  };

  return (
    <>
      {infoFields.map((infoField, index) => (
        <div className={styles.row} key={index}>
          {getIcon(infoField.icon)}
          <Text text={infoField.text} fontSize="body" fontWeight="medium" />
        </div>
      ))}
    </>
  );
};
