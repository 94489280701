import {
  setCourse,
  setCourseError,
  setCourseIsLoading,
  setModuleCompleted,
} from '.';
import { AppDispatch } from '../../store';
import {
  getCourseById,
  postModuleCompleted,
} from '../../utils/api/academy-api';

export const fetchCourseById =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setCourseIsLoading(true));
      const course = await getCourseById(id);
      dispatch(setCourse(course));
      dispatch(setCourseIsLoading(false));
    } catch (error) {
      dispatch(setCourseIsLoading(false));
      dispatch(setCourseError('Error when fetching course'));
    }
  };

export const updateModuleCompleted =
  (courseId: string, moduleId: string) => async (dispatch: AppDispatch) => {
    try {
      await postModuleCompleted(courseId, moduleId);
      dispatch(setModuleCompleted(moduleId));
      const course = await getCourseById(courseId);
      dispatch(setCourse(course));
    } catch (error) {
      dispatch(setCourseError('Error when updating course status'));
    }
  };
