import StyledClass from './Row.module.css';

interface RowProps {
  children?: React.ReactNode | React.ReactNode[];
  textAlign?: 'center' | 'left' | 'right';
}

export const Row = (props: RowProps) => {
  const { textAlign = 'left' } = props;
  return (
    <div className={`${StyledClass.container} ${StyledClass[textAlign]}`}>
      {props.children}
    </div>
  );
};
