import styles from './Avatar.module.css';
import { Icons } from '../../assets/icons';

interface AvatarProps {
  /** If not provided, default user-icon will be used */
  url?: string;
}

export const Avatar = (props: AvatarProps) => {
  return (
    <div className={styles.container}>
      {props.url ? (
        <img
          src={props.url}
          alt="profile"
          className={`${styles.profileCircle} ${styles.photo}`}
        />
      ) : (
        <Icons.BustInSilhouette className={styles.profileCircle} />
      )}
    </div>
  );
};
