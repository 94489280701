export const CrossArrows = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.5 0.5H0.5V5.5H2.16667V3.30558L6.24208 7.381L7.42058 6.20248L3.38477 2.16667H5.5V0.5ZM5.5 15.5H0.5V10.5H2.16667V12.6233L6.24198 8.548L7.4205 9.7265L3.31367 13.8333H5.5V15.5ZM10.5 15.5H15.5V10.5H13.8333V12.6037L9.77767 8.548L8.59917 9.7265L12.706 13.8333H10.5V15.5ZM10.5 0.5H15.5V5.5H13.8333V3.32527L9.77758 7.381L8.59908 6.20251L12.6349 2.16667H10.5V0.5Z"
      />
    </svg>
  );
};
