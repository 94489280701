export const CirclePartiallyCrossed = ({
  className,
}: {
  className?: string;
}) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.81802 8.182C3.57538 9.93935 6.4246 9.93935 8.182 8.182C9.93935 6.4246 9.93935 3.57538 8.182 1.81802C6.4246 0.06066 3.57538 0.06066 1.81802 1.81802C0.06066 3.57538 0.06066 6.4246 1.81802 8.182ZM2.90375 7.80335C4.27465 8.83115 6.2281 8.72165 7.47485 7.47485C8.72165 6.2281 8.83115 4.27465 7.80335 2.90375L6.4142 4.2929C6.21895 4.48815 5.90235 4.48815 5.7071 4.2929C5.51185 4.09765 5.51185 3.78105 5.7071 3.58578L7.09625 2.19664C5.72535 1.16884 3.77192 1.27833 2.52513 2.52513C1.27833 3.77192 1.16884 5.72535 2.19664 7.09625L3.58578 5.7071C3.78105 5.51185 4.09765 5.51185 4.2929 5.7071C4.48815 5.90235 4.48815 6.21895 4.2929 6.4142L2.90375 7.80335Z"
        fill="currentColor"
      />
    </svg>
  );
};
