import { Location } from 'react-router-dom';
import { reroutable_routes } from '../AppRoutes';

export const processInternalReroutablePaths = (
  internalPath: string,
  location: Location<any>,
): string | undefined => {
  if (!internalPath || !location) return undefined;

  for (let i = 0; i < reroutable_routes.length; i++) {
    const route = reroutable_routes[i];
    if (internalPath.match(route))
      return location.pathname + (location.search ?? '');
  }

  return undefined;
};
