import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CourseState } from './types';
import { UserCourseResult } from '../../utils/interfaces';
import { RootState } from '../../store';

const initialState: CourseState = {
  course: null,
  isLoading: false,
  error: null,
};

const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    setCourse: (state, action: PayloadAction<UserCourseResult>) => {
      state.course = action.payload;
    },
    setModuleCompleted: (state, action: PayloadAction<string>) => {
      if (!state.course) {
        console.error('No course is state');
        return;
      }
      const moduleId = action.payload;
      state.course.modules.find(
        (module) => module._id === moduleId,
      )!.completed = true;
    },
    setCourseIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setCourseError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const {
  setCourse,
  setModuleCompleted,
  setCourseIsLoading,
  setCourseError,
} = courseSlice.actions;

export const selectCourseIsLoading = (state: RootState) =>
  state.course.isLoading;
export const selectCourseError = (state: RootState) => state.course.error;
export const selectCourse = (state: RootState) => state.course.course;

export default courseSlice.reducer;
