export const CrossRound = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12ZM16.3891 7.90911C16.7657 8.31312 16.7434 8.94589 16.3394 9.32245L13.4133 12.0497L16.1405 14.9758C16.5171 15.3798 16.4948 16.0126 16.0908 16.3891C15.6868 16.7657 15.054 16.7434 14.6775 16.3394L11.9502 13.4133L9.0241 16.1405C8.62008 16.5171 7.98731 16.4948 7.61076 16.0908C7.23421 15.6868 7.25647 15.054 7.66048 14.6775L10.5866 11.9502L7.85938 9.0241C7.48283 8.62008 7.50509 7.98731 7.90911 7.61076C8.31312 7.23421 8.94589 7.25647 9.32245 7.66048L12.0497 10.5866L14.9758 7.85938C15.3798 7.48283 16.0126 7.50509 16.3891 7.90911Z"
        fill="currentColor"
      />
    </svg>
  );
};
