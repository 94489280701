import { ReadModuleDto } from './icourseModule';
import { UserCourseResult } from './userCourseResult';

export const getNextModule = (
  course: UserCourseResult,
  moduleId: string,
): ReadModuleDto | undefined => {
  const currentModuleIndex = course.modules.findIndex(
    (module) => module._id === moduleId,
  );
  if (currentModuleIndex === -1) {
    throw new Error('Module not found');
  }

  const lastModule = currentModuleIndex === course.modules.length - 1;
  if (lastModule) {
    return course.modules.find((module) => !module.completed);
  }

  return course.modules[currentModuleIndex + 1];
};

const getNumberOfCompletedModules = (course: UserCourseResult): number => {
  return course.modules.filter((module) => module.completed).length;
};

export const getPercentageComplete = (course: UserCourseResult) => {
  const numberOfModulesCompleted = getNumberOfCompletedModules(course);
  const percentageOfModulesCompleted =
    (100 * numberOfModulesCompleted) / course.modules.length;
  return Math.round(percentageOfModulesCompleted);
};
