export const Loader = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="true"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.2" filter="url(#filter0_d_2201_6105)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 19C17.866 19 21 15.866 21 12C21 8.13401 17.866 5 14 5C10.134 5 7 8.13401 7 12C7 15.866 10.134 19 14 19ZM14 22C19.5228 22 24 17.5228 24 12C24 6.47715 19.5228 2 14 2C8.47715 2 4 6.47715 4 12C4 17.5228 8.47715 22 14 22Z"
          fill="currentColor"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12C4 10.6868 4.25866 9.38642 4.76121 8.17316C5.26375 6.95991 6.00035 5.85752 6.92893 4.92893C7.85752 4.00034 8.95991 3.26375 10.1732 2.7612C11.3864 2.25866 12.6868 2 14 2L14 4.99531C13.0801 4.99531 12.1693 5.17649 11.3194 5.52851C10.4696 5.88053 9.69739 6.39649 9.04694 7.04694C8.3965 7.69738 7.88053 8.46957 7.52852 9.31942C7.1765 10.1693 6.99532 11.0801 6.99531 12L4 12Z"
        fill="currentColor"
      />
    </svg>
  );
};
